import { Dropdown, DropdownProps, Option } from "../../dropdown";

export type AnnotationEditorTypeSelectionProps = {
  /** The label of the dropdown */
  label?: string;

  /** The value to display in the dropdown */
  value?: string;

  /** The dropdown available options */
  options: Option[];

  /** The style to apply to the dropdown */
  style?: DropdownProps["sx"];

  /** Callback function when a new value is selected */
  onChange: DropdownProps["onChange"];

  /** Indicates if the dropdown is disabled */
  disabled?: boolean;
};

/**
 * @returns A dropdown allowing to select the type of annotation to create
 */
export function AnnotationEditorTypeSelection({
  label = "Annotation Type",
  value,
  options,
  style,
  onChange,
  disabled,
}: AnnotationEditorTypeSelectionProps): JSX.Element {
  return (
    <Dropdown
      label={label}
      value={value}
      options={options}
      dark
      fullWidth
      sx={style}
      onChange={onChange}
      disabled={disabled}
      shouldCapitalize={false}
    />
  );
}
