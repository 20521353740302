import { generateGUID, GUID } from "@faro-lotv/foundation";
import {
  createIElement,
  IElementGroup,
  IElementType,
  IElementTypeHint,
  IElementVolumeBBox,
  IVec3,
  WithHint,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  createBaseMutation,
  MutationTypes,
} from "./mutation-base";

export interface MutationAddVolume extends BaseMutation {
  /** The group containing the volumes */
  group: CreateIElement<IElementGroup>;

  /** New volume bounding box */
  newElement: CreateIElement<IElementVolumeBBox>;
}

export interface CreateMutationAddVolume {
  /** ID of the area */
  areaId: GUID;

  /** ID of the root element */
  rootId: GUID;

  /** Size of the volume */
  size: IVec3;

  /** The current user ID */
  userId?: GUID;

  /**
   * Date of creation of this node, in ISO string format
   *
   * @default today
   */
  createdAt?: string;

  /**
   * Bounding boxes that together define the volume of the area
   */
  group?: WithHint<IElementGroup, IElementTypeHint.volumes>;
}

/**
 * Adds a new volume to an existing Area
 *
 *```
 * Section (Area)
 * └ Group (Volumes)
 *  └ VolumeBBox
 * ```
 *
 * @returns A MutationAddVolume object to create a volume
 */
export function createMutationAddVolume({
  areaId,
  rootId,
  group,
  size,
  userId,
  createdAt = new Date().toISOString(),
}: CreateMutationAddVolume): MutationAddVolume {
  const id = generateGUID();

  const groupEl =
    group ??
    createIElement<IElementGroup>({
      id: generateGUID(),
      type: IElementType.group,
      typeHint: IElementTypeHint.volumes,
      xOr: false,
      rootId,
      name: "Volumes",
      createdBy: userId ?? "",
      parentId: areaId,
      createdAt,
    });

  const newElement: CreateIElement<IElementVolumeBBox> = {
    type: IElementType.volumeBBox,
    id,
    rootId,
    parentId: groupEl.id,
    name: "Bounding Box",
    createdAt,
    size,
  };

  return {
    ...createBaseMutation(MutationTypes.MutationAddVolume, areaId),
    newElement,
    group: groupEl,
  };
}
