import {
  assert,
  compareDateTimes,
  getFileExtension,
  includes,
} from "@faro-lotv/foundation";
import {
  ExternalMarkupIElement,
  GUID,
  IElement,
  IElementGenericDataset,
  IElementGenericImgSheet,
  IElementImg360,
  IElementMarkup,
  IElementMarkupTemplate,
  IElementModel3D,
  IElementPointCloudStream,
  IElementProjectRoot,
  IElementSection,
  IElementTimeSeries,
  IElementType,
  IElementTypeHint,
  IPose,
  areCreatedOnSameDate,
  isCaptureTreeRoot,
  isIElementAreaSection,
  isIElementBimModelGroup,
  isIElementBimModelSection,
  isIElementDataSetVideoWalk,
  isIElementExternalMarkup,
  isIElementGenericDataset,
  isIElementGenericImgSheet,
  isIElementGenericPointCloudStream,
  isIElementGroup,
  isIElementImg360,
  isIElementMarkupAssigneeIdTemplate,
  isIElementMarkupIssueDueDateTemplate,
  isIElementMarkupIssueStatusTemplate,
  isIElementModel3d,
  isIElementPanoInOdometryPath,
  isIElementPanoramaImage,
  isIElementPointCloudStream,
  isIElementProjectRoot,
  isIElementSection,
  isIElementSectionDataSession,
  isIElementSectionGeoslam,
  isIElementSectionWithTypeHint,
  isIElementTimeseries,
  isIElementTimeseriesDataSession,
  isIElementVideoRecording,
  isIElementVideoRecordingTimeseries,
  isIElementWithTypeAndHint,
  isValid,
  isValidPose,
  pickClosestInTime,
  validateKnownIElementTypes,
} from "@faro-lotv/ielement-types";
import { ProjectAccessLevel } from "@faro-lotv/service-wires";
import { Selector, createSelector } from "@reduxjs/toolkit";
import { partition } from "lodash";
import { ProjectLoadingState, State } from "./i-elements-slice";
import { isIElementVideoModeCopy } from "./utils/i-element-video-mode-copy";
import { Matrix4, Matrix4Tuple, Vector3Tuple } from "./utils/math";
import { TreeData } from "./utils/tree-generation";
import {
  CachedWorldTransform,
  DEFAULT_TRANSFORM,
  IElementWithPose,
  ProjectApiPose,
  TransformOverrides,
  extractProjectApiLocalPose,
  getIElementWorldTransform,
  isInsideCaptureTree,
} from "./utils/world-transform-cache";

/**
 * @returns all loaded IElements.
 * @param state The current application state
 */
export function selectLoadedIElements(state: State): IElement[] {
  return Object.values(state.iElements.iElements).filter(isValid);
}

/**
 * @returns The IElement with the given ID, or `undefined` if the IElement is not loaded yet or the ID is not defined.
 * @param id ID of IElement
 */
export function selectIElement(id: GUID | null | undefined) {
  return ({ iElements }: State): IElement | undefined =>
    id ? iElements.iElements[id] : undefined;
}

/**
 * @param state AppState
 * @returns the tree representation of the current project
 */
export function selectProjectTree(state: State): TreeData[] {
  return state.iElements.tree;
}

/**
 * @param state AppState
 * @returns the id of the current project
 */
export function selectProjectId(state: State): GUID | undefined {
  return state.iElements.projectId;
}

/**
 * @param state AppState
 * @returns the rootId of the current project
 */
export function selectRootId(state: State): string | undefined {
  return state.iElements.rootId;
}

/**
 * @param state AppState
 * @returns the name of the current project
 */
export function selectProjectName(state: State): string | undefined {
  return state.iElements.projectName;
}

/**
 * @param state AppState
 * @returns the status of the current project
 */
export function selectProjectAccessLevel(
  state: State,
): ProjectAccessLevel | undefined {
  return state.iElements.projectAccessLevel;
}

/**
 * @param state AppState
 * @returns the url for the dashboard of this project
 */
export function selectDashboardUrl(state: State): string | undefined {
  return state.iElements.dashboardUrl;
}

/**
 * @returns the company ID for the current loaded project
 * @param state AppState
 */
export function selectCompanyId(state: State): GUID | undefined {
  return state.iElements.companyId;
}

/**
 * @returns Recursively gets all parents of the given element.
 *          Ordered ascending by distance to the passed IElement.
 * @param id ID of leave IElement
 */
export function selectIElementAncestors(id: GUID | undefined) {
  return ({ iElements }: State): GUID[] => {
    const ancestors: GUID[] = [];
    if (!id) {
      return ancestors;
    }
    let iElement = iElements.iElements[id];

    // iElement might be undefined in case of `id` being invalid
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!iElement) {
      return [];
    }

    while (iElement?.parentId) {
      ancestors.push(iElement.parentId);
      iElement = iElements.iElements[iElement.parentId];
    }

    return ancestors;
  };
}

/**
 * @returns true iff ancestorId is an ancestor of childId
 * @param ancestorId ID of ancestor
 * @param childId ID of child
 */
export function selectIsIElementAncestorOf(ancestorId: GUID, childId: GUID) {
  return (state: State): boolean => {
    let iElement = selectIElement(childId)(state);
    if (!iElement) return false;

    while (iElement.parentId) {
      if (iElement.parentId === ancestorId) return true;
      iElement = selectIElement(iElement.parentId)(state);
      if (!iElement) return false;
    }
    return false;
  };
}

/**
 * @returns All children of an IElement
 * @param id ID of parent IElement
 */
export function selectIElementChildren(id: GUID) {
  return ({ iElements }: State) => {
    const element = iElements.iElements[id];
    return element?.childrenIds
      ? element.childrenIds
          .map((childId) => {
            return iElements.iElements[childId];
          })
          .filter(isValid)
      : [];
  };
}

/**
 * @returns true if the given element is a section of location marker
 * @param id id of element
 */
export function selectIsLocationMarkerSection(id: GUID) {
  return (state: State): boolean => {
    const iElement = selectIElement(id)(state);

    if (!iElement) return false;

    if (iElement.type !== IElementType.section) return false;

    if (iElement.childrenIds?.length !== 1) return false;

    const timeseries = selectIElement(iElement.childrenIds[0])(state);
    if (!timeseries) return false;

    return (
      timeseries.type === IElementType.timeSeries &&
      (!timeseries.childrenIds || timeseries.childrenIds.length === 0)
    );
  };
}

/**
 * @returns Root IElement of the project
 * @throws if the rootId does not point to a IElementProjectRoot
 */
export function selectRootIElement({
  iElements,
}: State): IElementProjectRoot | undefined {
  const root = iElements.rootId
    ? iElements.iElements[iElements.rootId]
    : undefined;
  if (!root) return;
  assert(isIElementProjectRoot(root));
  return root;
}

/**
 * @returns the world transform of an IElement.
 * @param id ID of the IElement
 * @param transformOverrides Transforms to overwrite the persisted values with (only during the calculation).
 *  If defined, the global transform cache will be disabled.
 */
export function selectIElementWorldTransform(
  id?: GUID | undefined,
  transformOverrides?: TransformOverrides,
) {
  return (state: State): CachedWorldTransform => {
    if (!id) {
      return DEFAULT_TRANSFORM;
    }

    const iElement = state.iElements.iElements[id];

    // iElement might be undefined in case of `id` being invalid
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!iElement) {
      return DEFAULT_TRANSFORM;
    }

    // If the transforms are patched, we can't use the cache as some of the values might be invalid
    const cache = transformOverrides ? {} : state.iElements.worldTransformCache;

    return getIElementWorldTransform(
      state.iElements.iElements,
      iElement,
      cache,
      state.iElements.areaDataSets,
      transformOverrides,
    );
  };
}

/**
 * Returns the world pose matrix of a given iElement. The returned pose matrix
 * is guaranteed to be right-handed, and is therefore interoperable with three.js
 * and with the Viewer's computations.
 *
 * @returns The world transform of an IElement, as a Matrix4Tuple.
 * @param id ID of the IElement
 * @param transformOverrides Transforms to overwrite the persisted values with (only during the calculation).
 *  If defined, the global transform cache will be disabled.
 */
export function selectIElementWorldMatrix(
  id?: GUID | undefined,
  transformOverrides?: TransformOverrides,
): Selector<State, Matrix4Tuple> {
  return (state: State): Matrix4Tuple =>
    selectIElementWorldTransform(id, transformOverrides)(state).worldMatrix;
}

/**
 * @returns the world transform of a list of IElements.
 * @param ids IDs of the IElements
 */
export function selectIElementsWorldTransform(ids: GUID[]) {
  return (state: State) =>
    ids.map((id) => selectIElementWorldTransform(id)(state));
}

/**
 * @returns the world position of an IElement.
 *          Zero vector if the ID is undefined.
 * @param id ID of the IElement
 */
export function selectIElementWorldPosition(id: GUID | undefined) {
  return (state: State): Vector3Tuple =>
    selectIElementWorldTransform(id)(state).position;
}

/**
 * @returns the world position of a list of IElements.
 * @param ids IDs of the IElements
 */
export function selectIElementsWorldPosition(ids: GUID[]) {
  return (state: State) => {
    return ids.map((id) => selectIElementWorldPosition(id)(state));
  };
}

/**
 * @returns distance between the given IElement and the active floorplan (in meters)
 * @param id ID of the IElement
 * @param activeImgSheetId ID of the current active imageSheet
 */
export function selectAltitudeDifferenceToFloor(
  id: GUID,
  activeImgSheetId: GUID | undefined,
) {
  return (state: State) => {
    if (!activeImgSheetId) {
      return 0;
    }
    const worldPos = selectIElementWorldPosition(id)(state);

    const imgSheetWorldPos =
      selectIElementWorldPosition(activeImgSheetId)(state);

    return worldPos[1] - imgSheetWorldPos[1];
  };
}

/**
 * @returns the parent Timeseries of an  iElement or the direct timeseries child of a section
 * @param id ID of the IElement
 */
export function selectIElementTimeseries(id: GUID) {
  return (state: State): IElementTimeSeries => {
    const iElement = selectIElement(id)(state);
    let timeSeries = selectChildDepthFirst(
      iElement,
      isIElementTimeseries,
      1,
    )(state);
    if (timeSeries) return timeSeries;
    timeSeries = selectAncestor(iElement, isIElementTimeseries)(state);
    if (!timeSeries) {
      throw Error(`IElement with id ${id} is not part of a timeseries`);
    }
    return timeSeries;
  };
}

/**
 * @returns the Panorama iElement for the passed iElement,
 *      iff the iElement is or is inside the section of a single panorama.
 * @param id ID of the IElement
 */
export function selectIElementPanoramaElement(id: GUID) {
  return (state: State): IElement | undefined => {
    if (!id) {
      return;
    }
    const iElement = selectIElement(id)(state);
    if (!iElement) return;

    const children = selectIElementChildren(id)(state);

    const panoramaChild = children.find((child) =>
      isIElementPanoramaImage(child),
    );

    if (panoramaChild) {
      return panoramaChild;
    } else if (iElement.parentId) {
      return selectIElementPanoramaElement(iElement.parentId)(state);
    }
  };
}

/**
 * @returns the elements in the project that are of a certain type, already narrowed by the guard
 * @param guard a type guard to select the type we want
 * @param ancestorId Optional, Root element to begin the search to only include a sub tree
 */
export function selectAllIElementsOfType<T extends IElement>(
  guard: (element: IElement) => element is T,
  ancestorId?: GUID,
): (state: State) => T[];
/**
 * @returns the elements in the project of the selected types typed as IElement[]
 * @param types the list of types we want
 * @param ancestorId Optional, Root element to begin the search to only include a sub tree
 * @deprecated Prefer the overlad using a type guard as it returns a better typed array
 */
export function selectAllIElementsOfType(
  types: IElementType[],
  ancestorId?: GUID,
): (state: State) => IElement[];
/**
 * @returns the elements in the project of the selected types typed as IElement[] or narrowed if a type guard was used
 * @param guard the list of types we want or a type guard
 * @param ancestorId Optional, Root element to begin the search to only include a sub tree
 */
export function selectAllIElementsOfType(
  guard: IElementType[] | ((element: IElement) => boolean),
  ancestorId?: GUID,
): (state: State) => unknown[] {
  return (state: State): unknown[] => {
    const loadedIElements = selectLoadedIElements(state);
    // Keep implementation compatible with previous API
    const elements = Array.isArray(guard)
      ? loadedIElements.filter(
          (el) =>
            validateKnownIElementTypes(el.type) && guard.includes(el.type),
        )
      : loadedIElements.filter(guard);

    // Filter for ancestor after type filtering as in this way is less expensive as less
    // nodes are tested
    // We should rethink this for huge projects where a tree traversal approach from the ancestor may be faster
    if (ancestorId) {
      return elements.filter((el) =>
        selectIsIElementAncestorOf(ancestorId, el.id)(state),
      );
    }
    return elements;
  };
}

/**
 * @returns From each time series in the project, selects the panorama image that is closest in time to
 * the time of a reference element, excluding panos represented in odometry paths.
 * @param referenceId the id of the element to use as a reference representing target time period
 * @param startFromId the id of an element to use as the root element of the search
 */
export function selectClosestPanosInTime(
  referenceId: GUID,
  startFromId?: GUID,
) {
  return (state: State): IElementImg360[] => {
    const { rootId } = state.iElements;
    const reference = selectIElement(referenceId)(state);
    if (!rootId || !reference) {
      return [];
    }

    // If we're inside a data session search the data session panos first
    if (isIElementSectionDataSession(reference)) {
      const dataSessionPanos = selectChildrenDepthFirst(
        reference,
        isIElementImg360,
      )(state);
      if (dataSessionPanos.length > 0) {
        return dataSessionPanos;
      }
    }

    const root = startFromId
      ? selectIElement(startFromId)(state)
      : state.iElements.iElements[rootId];

    const panos: IElementImg360[] = [];

    // Search in rooms timeseries
    const rooms = selectChildrenDepthFirst(root, (el) =>
      isIElementTimeseries(el, IElementTypeHint.room),
    )(state);
    for (const series of rooms) {
      const timeChildren = selectIElementChildren(series.id)(state).map(
        (e) => e.id,
      );
      const closest = selectClosestIElementInTime(
        referenceId,
        timeChildren,
      )(state);
      if (closest) {
        const pano = selectIElementPanoramaElement(closest.id)(state);
        if (pano && isIElementImg360(pano)) {
          panos.push(pano);
        }
      }
    }

    // Search in all the other data sessions
    const dataSessions = selectChildrenDepthFirst(
      root,
      isIElementSectionDataSession,
    )(state);

    const referenceDataSession = pickClosestInTime(reference, dataSessions);
    if (referenceDataSession) {
      const bestSessions = dataSessions.filter((s) =>
        areCreatedOnSameDate(s, referenceDataSession),
      );
      // If we got one and has 360 add them to the list
      for (const dataSession of bestSessions) {
        panos.push(
          ...selectChildrenDepthFirst(
            dataSession,
            (el): el is IElementImg360 =>
              isIElementImg360(el) && !isIElementPanoInOdometryPath(el),
          )(state),
        );
      }
    }
    return panos;
  };
}

/**
 * @returns the iElement closest in time to a reference element from a provided list, excluding panos represented in odometry paths.
 * @param referenceId ID of the reference IElement
 * @param candidateElementIds IDs of the potential IElements to chose from
 *
 * Element cloned from a videomode projects are ignored as they're duplicates of nodes
 * that already exists in the original VideoRecording path
 */
export function selectClosestIElementInTime(
  referenceId: GUID,
  candidateElementIds: GUID[] | null,
) {
  return (state: State): IElement | undefined => {
    if (!referenceId || !candidateElementIds?.length) {
      return;
    }

    // Ignore element cloned from videomode projects
    const candidates = candidateElementIds
      .map((id) => selectIElement(id)(state))
      .filter((el) => !!el && !isIElementVideoModeCopy(el));

    const iElement = selectIElement(referenceId)(state);
    if (!iElement) return;

    const referenceTime = Date.parse(iElement.createdAt);

    let closest = candidates[0];
    if (!closest) return;

    let closestTimeDiff = Math.abs(
      Date.parse(closest.createdAt) - referenceTime,
    );

    for (const element of candidates) {
      if (!element) continue;
      const timeDiff = Math.abs(Date.parse(element.createdAt) - referenceTime);
      if (timeDiff < closestTimeDiff) {
        closest = element;
        closestTimeDiff = timeDiff;
      }
    }

    return closest;
  };
}

/**
 * @returns the type of an iElement.
 * @param id ID of the IElement
 */
export function selectIElementType(id: GUID | undefined) {
  return (state: State): string | undefined => {
    if (!id) return undefined;

    const iElement = selectIElement(id)(state);

    return iElement?.type;
  };
}

/**
 * @returns the Panorama section of the passed iElement,
 *      iff the iElement is one or is inside of one.
 *  A Panorama section is a section that contains a Panorama Image as a direct child.
 * @param id ID of the IElement
 */
export function selectIElementPanoramaSection(id: GUID) {
  return (state: State): IElement | undefined => {
    const iElement = selectIElement(id)(state);
    if (!iElement) return;

    const children = selectIElementChildren(id)(state);

    const panoramaChild = children.find((child) =>
      isIElementPanoramaImage(child),
    );

    if (panoramaChild) {
      return iElement;
    } else if (iElement.parentId) {
      return selectIElementPanoramaSection(iElement.parentId)(state);
    }
  };
}

/**
 * @param timeSeriesId The id of the timeseries to query
 * @param targetId Id of the element we want the section of
 * @returns the section in the timeseries containing the IElement with the passed targetId
 */
export function selectTimeseriesSection(timeSeriesId: GUID, targetId: GUID) {
  return (state: State): IElement | undefined => {
    const target = selectIElement(targetId)(state);
    return selectAncestor(target, (el) => el.parentId === timeSeriesId)(state);
  };
}

/**
 * @returns Total number of aligned Point Clouds that are inside the provided floor,
 *   with the option to only count aligned PCs
 * @param floor The floor to check
 * @param onlyAlignment Whether or not to check only for aligned Point Clouds
 */
export function selectNumberOfPointCloudsOnFloor(
  floor?: IElementSection,
  onlyAlignment: boolean = false,
) {
  return (state: State): number => {
    return selectChildrenDepthFirst(
      floor,
      (pointCloud) =>
        isIElementGenericPointCloudStream(pointCloud) &&
        (!onlyAlignment || selectIsPointCloudAligned(pointCloud)(state)),
    )(state).length;
  };
}

/**
 * @param element The pointcloud or cad element to check if it's aligned
 * @returns Whether a given element is aligned
 */
export function selectIsElementAligned(element: IElement | undefined) {
  return (state: State): boolean => {
    if (!element) return false;

    if (isIElementSectionDataSession(element)) {
      return selectIsPointCloudAligned(element)(state);
    }
    return false;
  };
}

/**
 * @param pointCloud The pointcloud IElementSection that is an IElementSectionLaserScans
 * or a child to check
 * @returns Whether a given pointcloud is aligned
 */
export function selectIsPointCloudAligned(pointCloud: IElement | undefined) {
  return (state: State): boolean => {
    if (!pointCloud) {
      return false;
    }

    // Making sure we are always working on the section data session.
    const dataSession = selectAncestor(
      pointCloud,
      isIElementSectionDataSession,
    )(state);

    if (!dataSession) {
      return false;
    }
    return isValidPose(dataSession.pose);
  };
}

/**
 * @returns true if the floor has no scale set and at least a video recording is present in it
 * @param floor the floor element to check the scale of
 * @param requireVideoData require the project to contain video data to mark an area un-scaled
 */
export function selectIsFloorWithoutScale(
  floor?: IElement,
  requireVideoData: boolean = true,
) {
  return (state: State): boolean => {
    return (
      !!floor &&
      isIElementAreaSection(floor) &&
      !floor.pose?.scale &&
      !floor.pose?.isWorldScale &&
      (!requireVideoData ||
        !!selectChildDepthFirst(
          floor,
          (el) =>
            isIElementDataSetVideoWalk(el) || isIElementVideoRecording(el),
        )(state))
    );
  };
}

/**
 * @returns whether the element has an accurate scale relative to the real world.
 * Either directly or implicitly through one of its ancestors.
 * This is useful to determine e.g. if measurements on this element are meaningful.
 * @param element the element to check
 */
export function selectHasElementAccurateWorldScale(
  element: IElement | undefined,
) {
  return (state: State): boolean => {
    if (!element) return false;
    return !!selectAncestor(
      element,
      (ancestor) =>
        !!ancestor.pose?.isWorldScale ||
        // Assume if an area has a scale, that it is an accurate world scale
        (isIElementAreaSection(ancestor) && !!ancestor.pose?.scale) ||
        // old orbis data sets don't have the world scale flag set as they should have
        isIElementSectionGeoslam(ancestor) ||
        // Assume that datasets within the capture tree have a defined world scale through the alignment process
        isCaptureTreeRoot(ancestor),
    )(state);
  };
}

/**
 * Selector returns the *existing* IElement where a new Pointcloud will be attached to.
 * Returns a Pointcloud Timeseries if it already exists or the AreaSection otherwise.
 *
 * @param sheet The sheet to find the pointcloud root for
 * @returns IElement or undefined if the project is malformed
 */
export function selectRootForNewPointCloud(sheet: IElementGenericImgSheet) {
  return (state: State): IElement | undefined => {
    const areaSection = selectAncestor(
      sheet,
      (ancestor) =>
        isIElementSection(ancestor) &&
        ancestor.typeHint === IElementTypeHint.area,
    )(state);

    const laserScanTimeseries = selectChildDepthFirst(
      areaSection,
      isIElementTimeseriesDataSession,
    )(state);

    return laserScanTimeseries ?? areaSection;
  };
}

/**
 * Selector returns the *existing* IElement where a new CAD will be attached to.
 * Returns a CAD Group if it already exists or the AreaSection otherwise.
 *
 * @param sheet The sheet to find the pointcloud root for
 * @returns IElement or undefined if the project is malformed
 */
export function selectRootForNewCAD(sheet: IElementGenericImgSheet) {
  return (state: State): IElement | undefined => {
    const areaSection = selectAncestor(
      sheet,
      (ancestor) =>
        isIElementSection(ancestor) &&
        ancestor.typeHint === IElementTypeHint.area,
    )(state);

    const bimModelGroup = selectChildDepthFirst(
      areaSection,
      isIElementBimModelGroup,
    )(state);

    return bimModelGroup ?? areaSection;
  };
}

/**
 * @returns all IElements that are above the first currently selected element,
 *    that can be considered active as well
 * @param selectedIds The ids of the selected iElements
 */
export function selectActiveTree(selectedIds: GUID[]) {
  return (state: State): Record<GUID, IElement> => {
    const root = selectRootIElement(state);
    const ancestors = selectIElementAncestors(selectedIds[0])(state);

    const activeElements = ancestors.concat(selectedIds);

    /**
     * @returns all rendered children.
     * @param iElement Parent IElement
     */
    function getRenderedChildren(iElement: IElement): IElement[] {
      const children = selectIElementChildren(iElement.id)(state);
      if (children.length === 0) {
        return [];
      }

      // Handle xOr groups and time series
      if (
        (isIElementGroup(iElement) && iElement.xOr) ||
        iElement.type === "TimeSeries"
      ) {
        // Only show the child that is an ancestor of the currently selected element
        const selectedChild = children.find((child) =>
          activeElements.includes(child.id),
        );

        // Fall back to the first child
        return [selectedChild ?? children[0]];
      }

      return children;
    }

    /**
     * @returns the rendered iElements with the given root, recursively
     * @param root IElement to start from
     */
    function getRenderedIElements(
      root: IElement | undefined,
    ): Record<GUID, IElement> {
      if (!root) {
        return {};
      }

      let renderedElements = { [root.id]: root };

      for (const child of getRenderedChildren(root)) {
        const renderedChildren = getRenderedIElements(child);

        renderedElements = {
          ...renderedElements,
          ...renderedChildren,
        };
      }

      return renderedElements;
    }

    return getRenderedIElements(root);
  };
}

/**
 * @returns true, if all the selected ids are of panorama elements, else returns false
 * @param selectedIds The ids of the selected iElements
 */
export function selectAreAllSelectedIdsPanoramas(selectedIds: GUID[]) {
  return (state: State): boolean => {
    return !selectedIds.some(
      (id) => selectIElementPanoramaSection(id)(state) === undefined,
    );
  };
}

/**
 * @returns a list of all the 3D models that are not related with any panorama image
 * @param state Current state
 */
export function selectNonPano3dModels(state: State): IElementModel3D[] {
  return selectLoadedIElements(state)
    .filter(isIElementModel3d)
    .filter((iElement) => {
      if (selectIElementPanoramaSection(iElement.id)(state) !== undefined) {
        return false;
      }

      const fileExtension = getFileExtension(iElement.uri);
      return fileExtension === "gltf" || fileExtension === "glb";
    });
}

/**
 * @returns the recent child for a given iElements based on their created date
 * @param id Id of the IElement
 */
export function selectRecentChild(id: GUID) {
  return (state: State): IElement | undefined => {
    const children = selectIElementChildren(id)(state);
    children.sort((childA: IElement, childB: IElement) =>
      compareDateTimes(childA.createdAt, childB.createdAt),
    );
    return children.pop();
  };
}

/**
 * @returns if an iElement is a child of rooms or not
 * @param id - ID of an iElement
 */
export function selectIsChildOfRooms(id: GUID) {
  return (state: State): boolean => {
    const el = selectIElement(id)(state);
    const parentRoom = selectAncestor(el, (parent) => {
      return parent.type === "Group" && parent.typeHint === "Rooms";
    })(state);
    return parentRoom !== undefined;
  };
}

/**
 * Walk up the tree and select the first ancestor to match the predicate
 *
 * @param element Starting IElement
 * @param predicate Function to select the required ancestor
 * @returns The ancestor or undefined if no ancestor match the predicate
 */
export function selectAncestor<Type extends IElement>(
  element: IElement | undefined,
  predicate: (el: IElement) => el is Type,
): (state: State) => Type | undefined;
export function selectAncestor(
  element: IElement | undefined,
  predicate: (el: IElement) => boolean,
): (state: State) => IElement | undefined;
// eslint-disable-next-line jsdoc/require-jsdoc
export function selectAncestor(
  element: IElement | undefined,
  predicate: (el: IElement) => boolean,
) {
  return (state: State): IElement | undefined => {
    if (!element) return;
    if (predicate(element)) return element;
    while (element?.parentId) {
      element = selectIElement(element.parentId)(state);
      if (element && predicate(element)) return element;
    }
  };
}

/**
 * Recursive function to go down the tree (depth first) to find the child that match the predicate
 *
 * @param element Starting IElement
 * @param predicate Function to select the required child
 * @param maxDepth Max depth level, from the current IElement, to reach before the search is stopped.
 * If undefined, the search doesn't stop until it reaches the leaf nodes
 * @returns The IElement child that matches the predicate
 */
export function selectChildDepthFirst<Type extends IElement>(
  element: IElement | undefined,
  predicate: (el: IElement) => el is Type,
  maxDepth?: number,
): (state: State) => Type | undefined;
export function selectChildDepthFirst(
  element: IElement | undefined,
  predicate: (el: IElement) => boolean,
  maxDepth?: number,
): (state: State) => IElement | undefined;
// eslint-disable-next-line jsdoc/require-jsdoc
export function selectChildDepthFirst(
  element: IElement | undefined,
  predicate: (el: IElement) => boolean,
  maxDepth?: number,
) {
  return (state: State): IElement | undefined => {
    return selectChildrenDepthFirst(
      element,
      predicate,
      maxDepth,
      (children) => children.length === 1,
    )(state)[0];
  };
}

/** An utility type to implement selectChildrenDepthFirst without recursion */
type SelectChildStackItem = {
  /** The element in the stack we want to check */
  element: IElement;

  /** The depth of this element relative to the root of the query */
  depth: number;
};

/**
 * Finds all the children of a specific element that matches the predicate (depth first)
 *
 * @param element Starting IElement
 * @param predicate Function to select the required child
 * @param maxDepth Max depth level, from the current IElement, to reach before the search is stopped.
 * If undefined, the search doesn't stop until it reaches the leaf nodes
 * @param shouldStop Called after a child is found, return true to stop recursion
 * @returns The IElement child that matches the predicate
 */
export function selectChildrenDepthFirst<Type extends IElement>(
  element: IElement | undefined,
  predicate: (el: IElement) => el is Type,
  maxDepth?: number,
  shouldStop?: (elements: Type[]) => boolean,
): (state: State) => Type[];
export function selectChildrenDepthFirst(
  element: IElement | undefined,
  predicate: (el: IElement) => boolean,
  maxDepth?: number,
  shouldStop?: (elements: IElement[]) => boolean,
): (state: State) => IElement[];
// eslint-disable-next-line jsdoc/require-jsdoc
export function selectChildrenDepthFirst(
  element: IElement | undefined,
  predicate: (el: IElement) => boolean,
  maxDepth?: number,
  shouldStop: (elements: IElement[]) => boolean = () => false,
) {
  return (state: State): IElement[] => {
    const found: IElement[] = [];
    if (!element) return found;

    // A stack of elements to check with their depth from the starting element
    const stack: SelectChildStackItem[] = [{ element, depth: 0 }];

    while (stack.length > 0) {
      // Pop the element and depth from the stack
      const toCheck = stack.pop();
      if (!toCheck) continue;
      const { element, depth } = toCheck;

      // Check the search and early stop conditions
      if (predicate(element)) {
        found.push(element);
        if (shouldStop(found)) {
          return found;
        }
      }

      // Don't compute children if the depth is already at the max allowed depth
      if (
        !element.childrenIds ||
        (maxDepth !== undefined && depth === maxDepth)
      ) {
        continue;
      }

      // Check for children in reverse order to maintain order when we pop from the stack
      for (let idx = element.childrenIds.length - 1; idx >= 0; --idx) {
        const child = selectIElement(element.childrenIds[idx])(state);
        if (child) {
          stack.push({ element: child, depth: depth + 1 });
        }
      }
    }
    return found;
  };
}

/**
 *
 * @param img The argument pano image
 * @returns Whether the pano image has a valid pose
 */
export function selectHasValidPose(img?: IElementImg360) {
  return (state: State): boolean => {
    if (!img) {
      return false;
    }

    /**
     *
     * @param pose The argument pose
     * @returns Whether the argument pose is a null pose
     */
    function isNullPose(pose: IPose | null | undefined): boolean {
      return !pose || (!pose.pos && !pose.rot && !pose.scale);
    }

    const section = selectAncestor(img, isIElementSection)(state);
    const timeseries = selectAncestor(img, isIElementTimeseries)(state);
    const tsection = selectAncestor(timeseries, isIElementSection)(state);
    return (
      !isNullPose(img.pose) ||
      section === undefined ||
      !isNullPose(section.pose) ||
      timeseries === undefined ||
      !isNullPose(timeseries.pose) ||
      tsection === undefined ||
      !isNullPose(tsection.pose)
    );
  };
}

/** Filter function used for the selectSameTimestampPanos hook */
export type SameTimestampPanosFilter = (
  pano: IElementImg360,
) => (state: State) => boolean;

/**
 * Selector to pick all the other panos with the same timestamp in the reference area section
 *
 * @param referenceAreaSectionId The area section we want to collect the panos for
 * @param referenceId - The id of the element to use as a reference representing target time period
 * @param filterFn - A selector function which is used for filtering the result as required
 * @returns All the pano at the same timestamp of the reference pano or the active one
 */
export function selectSameTimestampPanos(
  referenceAreaSectionId: GUID,
  referenceId?: GUID,
  filterFn?: SameTimestampPanosFilter,
): (state: State) => IElementImg360[] {
  return (state: State): IElementImg360[] => {
    if (!referenceId) return [];
    const sheet = selectIElement(referenceAreaSectionId)(state);
    const element = selectIElement(referenceId)(state);
    if (!element) return [];

    const referenceElement = selectAncestor(
      element,
      (el) =>
        isIElementVideoRecording(el) ||
        isIElementSectionDataSession(el) ||
        isIElementSectionWithTypeHint(el, IElementTypeHint.room),
    )(state);

    if (!referenceElement) {
      return [];
    }

    const areaSection = selectAncestor(
      sheet,
      (el): el is IElementSection =>
        el.type === IElementType.section &&
        el.typeHint === IElementTypeHint.area,
    )(state);

    if (!areaSection) {
      throw new Error(
        "Project without an area section or pano not in a section",
      );
    }

    // Get all the panos for the current area section and timestamp
    const panos = selectClosestPanosInTime(
      referenceElement.id,
      areaSection.id,
    )(state);

    return filterFn ? panos.filter((pano) => filterFn(pano)(state)) : panos;
  };
}

/**
 * Compare to array of ielements for equality using the IElement id
 *
 * @param a First array
 * @param b Second array
 * @returns True if they are the same
 */
export function areIElementArraysEqual<T extends IElement>(
  a: T[],
  b: T[],
): boolean {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i].id !== b[i].id) {
      return false;
    }
  }
  return true;
}

/**
 * @returns the creation date of the section, child of a time series, parent of the passed element
 * @param element The IElement we want to query the time series section creation date of
 */
export function selectElementTimeSeriesSectionCreationDate(element: IElement) {
  return (state: State) => {
    const root = selectRootIElement(state);
    if (!root) throw Error("Invalid project without a root");

    function predicate(element: IElement): boolean {
      if (!isIElementSection(element)) {
        return false;
      }
      if (!element.parentId) {
        return false;
      }
      const parent = selectIElement(element.parentId)(state);
      return parent?.type === IElementType.timeSeries;
    }

    const section = selectAncestor(element, predicate)(state);
    return new Date((section ?? root).createdAt).getTime();
  };
}

/**
 * @returns Current loading state of the project
 * @param state The store
 */
export function selectProjectLoadingState(state: State): ProjectLoadingState {
  return state.iElements.projectLoadingState;
}

/**
 * @returns If the target element id is the root of a tree that is being loaded
 * @param {GUID} subtreeRootId - The id of the element to check
 */
export function selectIsSubtreeLoading(subtreeRootId: GUID) {
  return (state: State): boolean =>
    state.iElements.loadingSubTrees.includes(subtreeRootId);
}

/**
 * @returns true if a subtree of the project is loaded
 * @param subtreeRootId id of the root of the subtree to check
 */
export function selectIsSubtreeLoaded(subtreeRootId?: GUID) {
  return (state: State): boolean => {
    if (!subtreeRootId) {
      return false;
    }
    const node = selectIElement(subtreeRootId)(state);
    if (!node) {
      return false;
    }
    if (!node.childrenIds || node.childrenIds?.length === 0) {
      return true;
    }
    return node.childrenIds.every((id) => !!selectIElement(id)(state));
  };
}

/**
 * This selector returns the element where the time information is encoded for another elements in the project
 * Eg. To know the acquisition time of:
 * * an IElementPointCloudStream we need to find the IElementSection(DataSession) that contains it
 * * an IElementSection(OdometricPath) we need to find the IElementSection(VideoRecordings) that contains it
 *
 * @returns The element to use to identify the current moment the passed element was acquired (to make time based decision)
 * @param element The element for which we want to compute the reference
 * @param candidates The list of possible reference elements
 */
export function selectReferenceElement(
  element: IElement,
  candidates: IElementSection[],
) {
  return (state: State): IElementSection | undefined => {
    const reference = selectAncestor(element, (e) =>
      candidates.some((candidate) => e === candidate),
    )(state);
    if (reference) {
      assert(
        isIElementSection(reference),
        "As the reference is one of the candidate it must be section",
      );
      return reference;
    }

    const [preferred, others] = partition(
      candidates,
      (el) => isIElementSectionDataSession(el) || isIElementVideoRecording(el),
    );

    const preferredPick = pickClosestInTime(element, preferred);
    if (preferredPick) {
      return preferredPick;
    }

    return pickClosestInTime(element, others);
  };
}

/**
 * @returns The section/data session containing the input element
 * @param element The element for which we want to compute the reference data
 */
export function selectDatasetOrArea(element: IElement) {
  return (state: State): IElementSection => {
    // The returned type could be:
    //      - a data session (Section - DataSession)
    //      - an area (Section - Area)
    const reference = selectAncestor(
      element,
      (e) =>
        isIElementSectionDataSession(e) ||
        isIElementGenericDataset(e) ||
        isIElementSectionWithTypeHint(e, IElementTypeHint.area),
    )(state);
    // We expect at least the floor to always exist
    if (!reference || !isIElementSection(reference)) {
      throw new Error("Invalid element not wrapped in any section");
    }
    return reference;
  };
}

/**
 * @returns The list of data sessions in the current area that have 3d data available
 * @param area The input area
 */
export function selectAreaDataSessions(area: IElementSection) {
  return (state: State): IElementSection[] => {
    // Account for video recording still in a separate video recording timeseries
    const dataSessions = selectLegacyVideoRecordings(area)(state);

    // Enumerate DataSession from the BI-tree
    // TODO: Remove when all project will be migrated to the capture tree (https://faro01.atlassian.net/browse/SWEB-4468)
    const dataTimeSeries = selectChildDepthFirst(
      area,
      isIElementTimeseriesDataSession,
      1,
    )(state);
    if (dataTimeSeries) {
      dataSessions.push(
        ...selectIElementChildren(dataTimeSeries.id)(state).filter(
          isIElementSection,
        ),
      );
    }

    // Enumerate DataSets from the capture tree
    const dataSetIds = selectAreaCaptureTreeDataSetIds(state, area.id);
    for (const id of dataSetIds) {
      const section = selectIElement(id)(state);
      if (
        !section ||
        !isIElementSection(section) ||
        !!selectAncestor(section, isIElementSectionDataSession)(state)
      ) {
        continue;
      }
      dataSessions.push(section);
    }

    return dataSessions;
  };
}

interface SessionDataFilters {
  /** Filter function to exclude pointclouds from the session data. */
  pointCloudFilter?(pointcloud: IElementPointCloudStream): boolean;
}

/**
 * @returns what kind of data a data session contains
 * @param session to check
 */
export function selectDataSessionContainedData(
  session: IElement,
  { pointCloudFilter }: SessionDataFilters = {},
) {
  return (state: State): { panos: boolean; pointClouds: boolean } => {
    return {
      panos: !!selectChildDepthFirst(session, isIElementImg360)(state),
      pointClouds: !!selectChildDepthFirst(
        session,
        (el) =>
          isIElementPointCloudStream(el) &&
          (pointCloudFilter ? pointCloudFilter(el) : true),
      )(state),
    };
  };
}

/**
 * @param id of the element to check
 * @returns true if the passed data session element has a DataSetVideoWalk
 */
export function selectIsVideoWalkDataSession(id: GUID) {
  return (state: State): boolean => {
    const children = selectIElementChildren(id)(state);
    return children.some(isIElementDataSetVideoWalk);
  };
}

/**
 * @param id of the element to fetch
 * @returns an IElementGenericDataset element or undefined if the given id does not
 * exist or is not an IElementGenericDataset
 */
export function selectGenericDatasetElement(id: GUID) {
  return (state: State): IElementGenericDataset | undefined => {
    const element = selectIElement(id)(state);
    if (!element) return;
    if (isIElementGenericDataset(element)) {
      return element;
    }
  };
}

/**
 * This selector checks if the the data session is empty or not.
 *
 * @param session element to check the children of, to see if its empty
 * @returns true if the data session does not contain any element the sphere viewer can render
 */
export function selectIsDataSessionEmpty(session: IElement) {
  return (state: State): boolean => {
    // Check if the session contains any of the data we can show in the viewer
    // return early as soon as the first valid data is found
    // The code can't assume the valid data will be at a specific depth of the data structure
    // as in a focus project the scans could be at any depth
    return !selectChildDepthFirst(
      session,
      (element) =>
        isIElementGenericPointCloudStream(element) ||
        isIElementImg360(element) ||
        isIElementGenericImgSheet(element),
    )(state);
  };
}

/**
 * @returns The list of video recordings of an area still defined in a separate VideoRecordings time series
 * @param area The input area
 */
export function selectLegacyVideoRecordings(area: IElementSection) {
  return (state: State): IElementSection[] => {
    const videoRecordingTimeSeries = selectChildDepthFirst(
      area,
      isIElementVideoRecordingTimeseries,
      1,
    )(state);

    if (!videoRecordingTimeSeries) {
      return [];
    }
    return selectIElementChildren(videoRecordingTimeSeries.id)(state)
      .filter(isIElementVideoRecording)
      .filter((videoRecording) =>
        selectIsValidVideoRecording(videoRecording)(state),
      );
  };
}

/**
 * @returns true is the given video recording is a valid one
 * @param videoRecording video recording to be checked for validity
 */
export function selectIsValidVideoRecording(videoRecording: IElementSection) {
  return (state: State): boolean =>
    !!selectChildDepthFirst(videoRecording, isIElementImg360)(state);
}

/**
 * @returns The list of cad models of an area, not belonging to a data session
 * @param area The input area
 */
export function selectAreaBimModels(area: IElementSection) {
  return (state: State): IElementSection[] => {
    const cadGroup = selectChildDepthFirst(
      area,
      (el) =>
        isIElementWithTypeAndHint(
          el,
          IElementType.group,
          IElementTypeHint.bimModel,
        ),
      1,
    )(state);
    if (!cadGroup) return [];
    return selectChildrenDepthFirst(cadGroup, isIElementBimModelSection)(state);
  };
}

type AreaRoomsData = {
  /** The list of timeseries of rooms for the current area */
  roomsTimeSeries: IElementTimeSeries[];
  /** All the sections containing panos*/
  roomsSections: IElementSection[];
};

/**
 * @returns The list of rooms time series and room sections of an area, not belonging to a data session
 * @param area The input area
 * @param excludeVideoModeClones true to exclude the rooms cloned by video mode trajectories
 */
export function selectAreaRoomsData(
  area: IElementSection,
  excludeVideoModeClones = true,
) {
  return (state: State): AreaRoomsData => {
    const roomsGroup = selectChildDepthFirst(
      area,
      (el) =>
        isIElementWithTypeAndHint(
          el,
          IElementType.group,
          IElementTypeHint.rooms,
        ),
      1,
    )(state);
    if (!roomsGroup) {
      return { roomsTimeSeries: [], roomsSections: [] };
    }
    const roomsTimeSeries = selectChildrenDepthFirst(
      roomsGroup,
      isIElementTimeseries,
      2,
    )(state);
    const roomsSections = roomsTimeSeries
      .flatMap((room) => selectIElementChildren(room.id)(state))
      .filter(isIElementSection)
      .filter((el) => !excludeVideoModeClones || !isIElementVideoModeCopy(el));
    const allRoomsIds = roomsSections.map((section) => section.id);

    return {
      // Clean up rooms times series from timeseries containing only videomode clones
      roomsTimeSeries: roomsTimeSeries.filter((ts) =>
        ts.childrenIds?.some((id) => includes(allRoomsIds, id)),
      ),
      roomsSections,
    };
  };
}

/**
 * @returns the markup template for "Advanced Markups" (former HoloBuilder markups)
 * @param state current store state
 */
export function selectAdvancedMarkupTemplate(
  state: State,
): IElementMarkupTemplate | undefined {
  assert(state.iElements.rootId, "Expected rootId");
  const root = selectRootIElement(state);
  return selectChildDepthFirst(root, (el): el is IElementMarkupTemplate =>
    isIElementWithTypeAndHint(
      el,
      IElementType.markupTemplate,
      IElementTypeHint.advancedMarkup,
    ),
  )(state);
}

export interface AdvancedMarkupTemplateIds {
  /** id of the template */
  templateId: GUID;

  /** id of the assignee template field */
  assigneeTemplateId: GUID;

  /** id of the status template field */
  statusTemplateId: GUID;

  /** id of the due date template field */
  dueDateTemplateId: GUID;
}

/**
 * @returns the template ids for advanced markups and their fields. Or undefined if the template was not found in the project.
 * @param state current store state
 */
export function selectAdvancedMarkupTemplateIds(
  state: State,
): AdvancedMarkupTemplateIds | undefined {
  const template = selectAdvancedMarkupTemplate(state);

  const assigneeTemplateField = selectChildDepthFirst(
    template,
    isIElementMarkupAssigneeIdTemplate,
  )(state);

  const statusTemplateField = selectChildDepthFirst(
    template,
    isIElementMarkupIssueStatusTemplate,
  )(state);

  const dueDateTemplateField = selectChildDepthFirst(
    template,
    isIElementMarkupIssueDueDateTemplate,
  )(state);

  if (
    !template ||
    !assigneeTemplateField ||
    !statusTemplateField ||
    !dueDateTemplateField
  ) {
    return;
  }

  return {
    templateId: template.id,
    assigneeTemplateId: assigneeTemplateField.id,
    statusTemplateId: statusTemplateField.id,
    dueDateTemplateId: dueDateTemplateField.id,
  };
}

/**
 * If a markup is synced externally, it will have an additional sibling specific to the provider. e.g:
 * Model3d
 * L Markup
 * L MarkupBim360
 *
 * @param markup the markup IElement to check
 * @returns the external markup for a given markup IElement, or undefined if the markup is not synced externally.
 */
export function selectExternalSyncedMarkupFor(markup: IElementMarkup) {
  return (state: State): ExternalMarkupIElement | undefined => {
    if (!markup.parentId) return;
    return selectIElementChildren(markup.parentId)(state).find(
      isIElementExternalMarkup,
    );
  };
}

/**
 * @returns whether project viewers are allowed to view markups (as per the HoloBuilder project setting).
 * Or undefined if the permission is not defined.
 * @param state current store state
 */
export function selectMarkupsDisplayForViewers(
  state: State,
): boolean | undefined {
  const root = selectRootIElement(state);

  if (!root) return;

  return root.metaDataMap?.slideContainerMarkupSettings?.displayForViewers;
}

/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/check-param-names */
/**
 * @returns an array with all the IDs of the datasets inside the area
 * @param state application state
 * @param areaId the id of the area to query the datasets of
 */
export const selectAreaCaptureTreeDataSetIds = createSelector(
  [
    (state: State) => state.iElements.areaDataSets,
    (_: State, areaId: GUID | undefined) => areaId,
  ],
  (
    areaDataSets: State["iElements"]["areaDataSets"],
    areaId: GUID | undefined,
  ) => {
    if (!areaId) return [];
    return areaDataSets[areaId]?.map((dataSet) => dataSet.elementId) ?? [];
  },
);
/* eslint-enable jsdoc/require-param */
/* eslint-enable jsdoc/check-param-names */

/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/check-param-names */
/**
 * @returns an array with all the IDs of areas that contain a capture tree data-set.
 * @param state application state
 * @param dataSetId the id of the dataset to query the area for
 */
export const selectAreasForCaptureTreeDataSet = createSelector(
  [
    (state: State) => state.iElements.areaDataSets,
    (_: State, dataSetId: GUID | undefined) => dataSetId,
  ],
  (
    areaDataSets: State["iElements"]["areaDataSets"],
    dataSetId: GUID | undefined,
  ) => {
    if (!dataSetId) return [];

    return Object.entries(areaDataSets)
      .filter(([, dataSets]) =>
        dataSets?.find((ds) => ds.elementId === dataSetId),
      )
      .map(([areaId]) => areaId);
  },
);
/* eslint-enable jsdoc/require-param */
/* eslint-enable jsdoc/check-param-names */

/**
 * @param element reference element to start the search. It should be a panorama image.
 * @param predicate function used to filter the elements
 * @returns the IElement of the sibling pano, or undefined if there is none
 */
export function selectSiblingPano(
  element?: IElement,
  predicate?: (el: IElementImg360) => boolean,
) {
  return (state: State): IElementImg360 | undefined => {
    if (!element) return;

    const parentSection = selectAncestor(element, isIElementSection)(state);

    let siblingPanos = selectChildrenDepthFirst(
      parentSection,
      isIElementImg360,
      1,
    )(state);

    if (predicate) {
      siblingPanos = siblingPanos.filter(predicate);
    }

    return siblingPanos.find((pano) => pano.id !== element.id);
  };
}

/**
 * @returns The local pose of an IElement separated into its raw original components read from the Project API.
 * This is particularly useful when writing pose data to the Project API and converting from the Viewer CS to the Project API CS
 * @param iElement The element whose pose we want to compute
 * @param pose The new local pose in the viewer reference system
 * @param extraElements Optional elements that are not yet in the app state, for which we need to compute the world transform
 */
export function selectIElementProjectApiLocalPose(
  iElement: IElementWithPose,
  pose: Matrix4,
  extraElements?: IElementWithPose[],
) {
  return (state: State): ProjectApiPose => {
    const extraCache = extraElements
      ? extraElements.reduce<Record<GUID, IElementWithPose>>(
          (prev, current) => {
            prev[current.id] = current;
            return prev;
          },
          {},
        )
      : {};
    const iElements = {
      ...state.iElements.iElements,
      ...extraCache,
    };
    const worldTransform = getIElementWorldTransform(
      iElements,
      iElement,
      state.iElements.worldTransformCache,
      state.iElements.areaDataSets,
    );
    const parentElement = iElement.parentId
      ? iElements[iElement.parentId]
      : undefined;
    const parentWorldTransform = parentElement
      ? getIElementWorldTransform(
          iElements,
          parentElement,
          state.iElements.worldTransformCache,
          state.iElements.areaDataSets,
        )
      : DEFAULT_TRANSFORM;

    const isInCaptureTree = isInsideCaptureTree(iElement, iElements);
    return extractProjectApiLocalPose(
      iElement,
      pose,
      isInCaptureTree,
      worldTransform,
      parentWorldTransform,
    );
  };
}
