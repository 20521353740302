import { assert } from "@faro-lotv/foundation";
import { MAX_NAME_LENGTH } from "@faro-lotv/ielement-types";
import { Divider, Grid, Stack } from "@mui/material";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { neutral } from "../../colors";
import { Option } from "../../dropdown";
import { NoTranslate } from "../../no-translate";
import { FaroRichTextEditor } from "../../rich-text";
import {
  AnnotationEditorButtons,
  AnnotationEditorButtonsProps,
} from "./annotation-editor-buttons";
import {
  AnnotationEditorHeader,
  AnnotationEditorHeaderProps,
} from "./annotation-editor-header";
import {
  AnnotationEditorMetaFields,
  AnnotationEditorMetaFieldsProps,
} from "./annotation-editor-meta-fields";
import { AnnotationEditorTypeSelection } from "./annotation-editor-type-selection";

/** The maximum allowed number of characters in ProjectAPI for description of the annotation */
const MAX_DESCRIPTION_LENGTH = 10000;

export type AnnotationTypeProps = {
  /**
   * Indicates if the creation of external markups is allowed.
   * When the enableExternalMarkups is false or undefined, the other props are unnecessary.
   */
  enableExternalMarkups?: boolean;

  /** Indicates the type of annotation that should be created. */
  annotationType?: string;

  /** Function called when the selected value of the annotation type dropdown field changes */
  onAnnotationTypeChange?(value: string): void;

  /** List of the available annotation type that can be created */
  annotationTypeOptions?: Option[];
};

export type AnnotationEditorProps = AnnotationEditorHeaderProps &
  AnnotationEditorMetaFieldsProps &
  AnnotationEditorButtonsProps &
  AnnotationTypeProps & {
    /**
     * Initial value of the description text field.
     * Updating this prop from parent will have no effect!
     */
    initialDescription?: string;

    /** Function called when the content of the description text field changes */
    onDescriptionChange?(description?: string): void;

    /**
     * Method to call if there's an error with the description
     */
    onDescriptionError(error: Error): void;
  };

/**
 * @returns a dialog used to create and edit annotations and their fields value
 */
export function AnnotationEditor({
  children,
  title,
  initialDescription = "",
  assignee = "",
  initialDate,
  status = "",
  annotationType,
  assigneeOptions,
  statusOptions,
  annotationTypeOptions,
  tagsOptions,
  confirmButtonText,
  attachments,
  tags,
  addNewAttachment,
  onTitleChange,
  onDescriptionChange,
  onAssigneeChange,
  onDueDateChange,
  onStatusChange,
  onAnnotationTypeChange,
  onTagsChange,
  onAddNewTagClick,
  onCancelButtonClick,
  onConfirmButtonClick,
  onAttachmentOpened,
  enableExternalMarkups = false,
  autoFocusTitle = false,
  shouldShowAttachments,
  isSaving,
  progress,
  onDescriptionError,
}: PropsWithChildren<AnnotationEditorProps>): JSX.Element {
  const [descriptionLength, setDescriptionLength] = useState(
    initialDescription.length,
  );

  const handleDescriptionChange = useCallback(
    (newDescription: string) => {
      setDescriptionLength(newDescription.length);
      onDescriptionChange?.(newDescription);
    },
    [onDescriptionChange],
  );

  if (enableExternalMarkups) {
    assert(
      annotationType !== undefined &&
        onAnnotationTypeChange &&
        annotationTypeOptions,
      "Invalid props for AnnotationEditor when enableExternalMarkups is true",
    );
  }

  const finalAnnotationTypeOptions = useMemo(
    () =>
      annotationTypeOptions!.map((o) => ({
        ...o,
        label: <NoTranslate>{o.label}</NoTranslate>,
      })),
    [annotationTypeOptions],
  );

  return (
    <Stack
      direction="column"
      sx={{
        width: "522px",
        backgroundColor: neutral[999],
        p: 2,
        borderRadius: "4px",
        pointerEvents: isSaving ? "none" : undefined,
        flexGrow: 1,
        gap: 3,
        // Allow the main content to scroll if there's not enough space
        overflowY: "auto",
        // but do not add an horizontal scrollbar when the main content needs to scroll
        overflowX: "clip",
      }}
    >
      {enableExternalMarkups && (
        <>
          <Grid item xs={6} py={0}>
            <AnnotationEditorTypeSelection
              value={annotationType!}
              options={finalAnnotationTypeOptions}
              onChange={(e) => onAnnotationTypeChange!(e.target.value)}
              disabled={isSaving}
            />
          </Grid>
          <Divider sx={{ bgcolor: neutral[0] }} />
        </>
      )}
      <AnnotationEditorHeader
        title={title}
        autoFocusTitle={autoFocusTitle}
        onTitleChange={onTitleChange}
        isSaving={isSaving}
      />
      <Stack
        sx={{
          // Add a scrollbar only to the main content if needed
          overflow: "auto",
          flexGrow: 1,
          gap: 3,
          mx: -0.875,
          px: 0.875,
        }}
      >
        <FaroRichTextEditor
          initialText={initialDescription}
          dark
          fullWidth
          readOnly={isSaving}
          label="Description"
          placeholder="Insert a description"
          onError={onDescriptionError}
          onChange={handleDescriptionChange}
          sx={{
            height: "120px",
          }}
        />

        {children}
        <AnnotationEditorMetaFields
          isSaving={isSaving}
          assignee={assignee}
          initialDate={initialDate}
          status={status}
          assigneeOptions={assigneeOptions}
          statusOptions={statusOptions}
          tagsOptions={tagsOptions}
          attachments={attachments}
          tags={tags}
          addNewAttachment={addNewAttachment}
          onAddNewTagClick={onAddNewTagClick}
          onAssigneeChange={onAssigneeChange}
          onDueDateChange={onDueDateChange}
          onStatusChange={onStatusChange}
          onTagsChange={onTagsChange}
          shouldShowAttachments={shouldShowAttachments}
          progress={progress}
          onAttachmentOpened={onAttachmentOpened}
        />
      </Stack>
      <AnnotationEditorButtons
        isConfirmButtonDisabled={
          !title ||
          title.length === 0 ||
          title.length > MAX_NAME_LENGTH ||
          descriptionLength > MAX_DESCRIPTION_LENGTH
        }
        isSaving={isSaving}
        confirmButtonText={confirmButtonText}
        onCancelButtonClick={onCancelButtonClick}
        onConfirmButtonClick={onConfirmButtonClick}
      />
    </Stack>
  );
}
