import { assert } from "@faro-lotv/foundation";
import { Stack, SxProps, Theme } from "@mui/material";
import { clamp } from "lodash";
import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { interpolateColor } from "../../utils";
import { ColorString, neutral } from "../colors";
import { FaroText } from "../text/faro-text/faro-text";

type InterpolatedColorCallback = (
  /**
   * The color of the bar at the current position.
   * Or undefined if the passed colors or normalized value are out of the range
   */
  color: ColorString | undefined,

  /** The normalized value of the bar at the current position */
  normalizedValue: number,
) => void;

/** Array of tow colors */
type TwoColors = [ColorString, ColorString];

/**
 * Array of colors with their ratio in the gradient.
 * The color ratios must be in non decreasing order.
 */
export type ColorsWithRatio = Array<{
  /** Hex color string */
  color: ColorString;
  /** Ratio [0,1] of the color in the gradient */
  ratio: number;
}>;

function isTwoColors(colors: TwoColors | ColorsWithRatio): colors is TwoColors {
  return colors.length === 2 && typeof colors[0] === "string";
}

type ColorBarProps = {
  /** The colors of the gradient */
  colors: TwoColors | ColorsWithRatio;

  /** Values displayed next to the color bar */
  values?: {
    /** The start number value of the bar */
    startValue: number;

    /** The end number value of the bar */
    endValue: number;

    /** The unit of measure of the values */
    unit: string;
  };

  /** The direction of the color bar */
  direction?: "horizontal" | "vertical";

  /** Optional style to apply to the component */
  sx?: SxProps<Theme>;

  /** Function called when the mouse is hovering the color bar */
  onPointerMove?: InterpolatedColorCallback;

  /** Function called when the color bar is clicked */
  onClick?: InterpolatedColorCallback;
};

/**
 * @returns A bar with a gradient from the first color to the second color
 */
export function ColorBar({
  colors,
  values,
  direction = "horizontal",
  sx,
  onPointerMove,
  onClick,
}: ColorBarProps): JSX.Element {
  assert(colors.length > 1, "ColorBar: colors must have at least 2 colors");
  const isHorizontal = useMemo(() => direction === "horizontal", [direction]);
  const containerRef = useRef<HTMLDivElement>(null);

  const colorGradient = useMemo(
    () =>
      isTwoColors(colors)
        ? `${colors[0]}, ${colors[1]}`
        : colors.map((c) => `${c.color} ${c.ratio * 100}%`).join(", "),
    [colors],
  );

  const computeInterpolatedColor = useCallback(
    (
      e: ReactMouseEvent<HTMLDivElement, MouseEvent>,
      callback?: InterpolatedColorCallback,
    ) => {
      if (!containerRef.current || !callback) return;
      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const normalizedValue = clamp(
        isHorizontal ? x / rect.width : 1 - y / rect.height,
        0,
        1,
      );
      let interpolatedColor;
      if (isTwoColors(colors)) {
        interpolatedColor = interpolateColor(
          colors[0],
          colors[1],
          normalizedValue,
        );
      } else {
        // find the sub range where the normalized value is
        for (let i = 0; i < colors.length - 1; i++) {
          assert(
            colors[i + 1].ratio >= colors[i].ratio,
            "Color ratios expected to be in non decreasing order",
          );
          if (normalizedValue <= colors[i + 1].ratio) {
            // deal with the case where two consecutive ratios are same
            if (colors[i].ratio === colors[i + 1].ratio) {
              interpolatedColor = colors[i].color;
            } else {
              // interpolate in the sub range
              interpolatedColor = interpolateColor(
                colors[i].color,
                colors[i + 1].color,
                (normalizedValue - colors[i].ratio) /
                  (colors[i + 1].ratio - colors[i].ratio),
              );
            }
            break;
          }
        }
      }
      callback(interpolatedColor, normalizedValue);
    },
    [isHorizontal, colors],
  );

  return (
    <Stack
      direction={isHorizontal ? "column" : "row"}
      // Add small padding between the color bar and the values' text
      gap={0.5}
      sx={sx}
      ref={containerRef}
    >
      <Stack
        sx={{
          p: 1,
          borderRadius: 2,
          outline: `1px solid ${neutral[1000]}33`,
          // Make the gradient go from left to right or from bot to top based on the direction prop
          background: `linear-gradient(to ${isHorizontal ? "right" : "top"}, ${colorGradient})`,
        }}
        onPointerMove={(e) => computeInterpolatedColor(e, onPointerMove)}
        onClick={(e) => computeInterpolatedColor(e, onClick)}
      />
      {values && (
        <Stack
          direction={isHorizontal ? "row" : "column-reverse"}
          justifyContent="space-between"
        >
          <FaroText color={neutral[400]} variant="bodyS">
            {values.startValue}
            {values.unit}
          </FaroText>
          <FaroText color={neutral[400]} variant="bodyS">
            {values.endValue}
            {values.unit}
          </FaroText>
        </Stack>
      )}
    </Stack>
  );
}
