import { Box, Stack, SxProps } from "@mui/material";
import {
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { BasePopover } from "../../faro-popover/base-popover";
import { FaroChipTag, FaroChipTagProps } from "../chip-tag/chip-tag";

export interface FaroChipListProps
  extends Pick<FaroChipTagProps, "size" | "dark"> {
  /** The chips to show. Can be arbitrary react nodes, but styles are optimized for FaroChip. */
  chips: ReactNode[];

  /** Whether to use dark styles for the popup */
  dark?: boolean;

  /** List custom styling */
  sx?: SxProps;
}

/**
 * @returns a horizontal list of chips that handles overflows by showing an additional "+n" chip with a dropdown menu
 */
export function FaroChipList({
  chips,
  dark,
  size,
  sx,
}: FaroChipListProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const [numVisible, setNumVisible] = useState(chips.length);

  // These wrapped chips are used to detect after which chip the "+n" chip should be inserted.
  const wrappedChips = container
    ? chips.map((c, index) => (
        <HideWhenOverflown
          key={index}
          container={container}
          // Add a bit of padding to account for the "+n" chip on the right
          rootMargin="0px -50px 0px 0px"
          onVisibilityChanged={(isVisible) =>
            setNumVisible((prevNumVisible) => {
              // Knowing whether a single chip is visible doesn't give us the numVisible state directly,
              // but it gives us an upper/lower bound that we can use to update the state.
              if (isVisible) {
                const minNumVisible = index + 1;
                return Math.max(minNumVisible, prevNumVisible);
              }
              return Math.min(index, prevNumVisible);
            })
          }
        >
          {c}
        </HideWhenOverflown>
      ))
    : [];

  const numOverflown = chips.length - numVisible;

  return (
    <Stack ref={setContainer} direction="row" gap={1} overflow="hidden" sx={sx}>
      {wrappedChips.slice(0, numVisible)}

      {numOverflown > 0 && (
        <Box ref={setAnchorEl} component="div">
          <FaroChipTag
            color={undefined}
            label={`+${numOverflown}`}
            dark={dark}
            size={size}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          />
          {anchorEl && (
            <BasePopover
              anchorEl={anchorEl}
              isAnimated
              open={isPopoverOpen}
              onClose={() => setIsPopoverOpen(false)}
              showCloseButton={false}
              dark={dark}
              popperSx={{
                minWidth: 0,
                maxHeight: 300,
                overflowY: "auto",
                px: 1.5,
                py: 1,
              }}
              popperOptions={{
                placement: "bottom-start",
              }}
            >
              <Stack gap={0.5}>{chips.slice(numVisible)}</Stack>
            </BasePopover>
          )}
        </Box>
      )}

      {
        // The invisible chips still need to be rendered, so they can be moved before the "+n" chip, if they come back into visibility
        wrappedChips.slice(numVisible)
      }
    </Stack>
  );
}

interface HideWhenOverflownProps {
  /** The container element to detect overflows with */
  container: HTMLDivElement;

  /** rootMargin to use in the intersection observer that detects overflows in the container */
  rootMargin?: string;

  /** callback executed when the visibility changes */
  onVisibilityChanged?(newVisibility: boolean): void;
}

/** @returns wrapped component that hides itself, when it overflows its parent. */
function HideWhenOverflown({
  container,
  rootMargin,
  onVisibilityChanged,
  children,
}: PropsWithChildren<HideWhenOverflownProps>): JSX.Element {
  const measureRef = useRef<HTMLDivElement>(null);

  const [visible, setVisibility] = useState(false);

  // Creates an IntersectionObserver to detect how much the element intersects its container
  useLayoutEffect(() => {
    function onIntersection(entries: IntersectionObserverEntry[]): void {
      const isInBounds = entries[0].intersectionRatio >= 1;
      setVisibility(isInBounds);
      onVisibilityChanged?.(isInBounds);
    }

    if (measureRef.current) {
      const observer = new IntersectionObserver(onIntersection, {
        root: container,
        rootMargin,
        // This threshold guarantees that all transitions above/below this value are reported.
        // Without it sometimes the full visibility is not reported when the parent container is scaled up.
        threshold: 1,
      });

      observer.observe(measureRef.current);

      return () => observer.disconnect();
    }
  }, [container, onVisibilityChanged, rootMargin]);

  return (
    <div
      ref={measureRef}
      style={{ visibility: visible ? "visible" : "hidden" }}
    >
      {children}
    </div>
  );
}
