// The BCF Services backend is mostly using the snake-case variable naming convention, but there are some exceptions.
/* eslint-disable @typescript-eslint/naming-convention */

import { TopicCustomField } from "./bcf-services-api-types";

/** Defines the possible actions/permissions that a user have in a project */
export enum ProjectActions {
  /** for permission to update the project */
  update = "update",
  /** for permission to create a new topic in the project */
  createTopic = "createTopic",
  /** for permission to upload/attach/create a document in the project ???*/
  createDocument = "createDocument",
}

/** Represents the authorization for a project */
export type ProjectAuthorization = {
  /** List of permissions/actions that are allow for a project */
  project_actions: ProjectActions[];
};

/** Represents the response for getting a project */
export type GetProject = {
  /** The id of the project */
  project_id: string;
  /** The name of the project */
  name: string;
  /** The authorization of the project */
  authorization: ProjectAuthorization;
};

/** Defines the possible actions/permissions that a user have when interacting with topics */
export enum TopicActions {
  /** for permission to update topics */
  update = "update",
  /** for permission to update related topics */
  updateRelatedTopics = "updateRelatedTopics",
  /** for permission to update document references */
  updateDocumentReferences = "updateDocumentReferences",
  /** for permission to update files associated to topics*/
  updateFiles = "updateFiles",
  /** for permission to create a comment for a topic */
  createComment = "createComment",
  /** for permission to create a viewpoint in a topic */
  createViewpoint = "createViewpoint",
  /** for permission to delete a topic */
  delete = "delete",
}

/** Defines the possible status value that a project can have */
export enum ProjectTopicStatusType {
  /** when the project is opened */
  open = "open",
  /** when the project is closed */
  closed = "closed",
}

/** Represents the definition of a status item in project extension */
export type ProjectTopicStatusExtensionItem = {
  /** Id of the project topic status */
  id: string;
  /** The name given to the topic status */
  name: string;
  /** The status associated to the topic status */
  status_type: ProjectTopicStatusType;
};

/** Defines the available actions that can be performed on a comment */
export enum CommentActions {
  /** for permission to update a comment */
  update = "update",
  /** for permission to delete a comment */
  delete = "delete",
}

/** Defines the possible types for a project custom field */
export enum ProjectCustomFieldType {
  /** for integer values */
  integer = "integer",
  /** for decimal values */
  decimal = "decimal",
  /** for string values */
  string = "string",
  /** for boolean values */
  boolean = "boolean",
  /** for enum values */
  enum = "enum",
  /** for date values */
  date = "date",
  /** for date-time values */
  dateTime = "date-time",
}

/** Represents all the values of an enum defined as a custom field in a project */
export type ProjectEnumValue = {
  /** The underlying value of an enum value */
  value: string;
  /** The displayed value of the enum value*/
  displayValue: string;
  /** Indicates if the enum is read only or can be edited */
  readonly: boolean;
};

/** Used to represent the definition of a custom field in a project.*/
export type ProjectCustomField = {
  /** The project custom field id */
  id: string;
  /** The name of the project custom field */
  name: string;
  /** The type of the project custom field */
  type: ProjectCustomFieldType;
  /** Indicates if the value of the custom field is read only */
  readonly: boolean;
  /** The minimum number of values associated to the project custom field */
  minArraySize: number;
  /** The maximum number of values associated to the project custom field */
  maxArraySize?: number | null;
  /** Defines the default value for the project custom field type */
  defaultValue?: string | null;
  /** The possible values of the project custom field if its an enum */
  enumValues?: ProjectEnumValue[] | null;
};

/** Represents a project extension item */
export type ProjectExtensionItem = GetTopicExtensionItem;

/** Represents an extension item for a project user in the BCF Services API */
export type ProjectUserExtensionItem = {
  /** The id of the user */
  id: string;
  /** The name of the user */
  name?: string | null;
  /** The email of the user. Not supposed to be nullable by API is currently returning null */
  email: string | null;
  /** Indicates if the associated values of the user are read only or can be modified */
  readonly: boolean;
};

/** Represents the response object for a collaboration user retrieved from the BCF Services API */
export type CollaborationUser = Omit<ProjectUserExtensionItem, "readonly">;

/**
 * Represents the response object for getting specific project extensions
 * Project extensions are used to define possible values that can be used in topics and comments,
 * for example topic labels and priorities.
 */
export type GetProjectExtensions = {
  /** The different possible status type of the project's topic */
  topic_type: ProjectExtensionItem[];
  /** The different possible status values for the project's topics */
  topic_status: ProjectTopicStatusExtensionItem[];
  /** The different possible label values for the project's topics*/
  topic_label: ProjectExtensionItem[];
  /** The different possible priority values for the project's topics */
  priority: ProjectExtensionItem[];
  /** The different possible users values for the project's topics */
  users: ProjectUserExtensionItem[];
  /** The different possible stage values for the project's topics */
  stage: ProjectExtensionItem[];
  /** The different possible project actions for the project's topics */
  project_actions: ProjectActions[];
  /** The different possible topic actions for the project's topics */
  topic_actions: TopicActions[];
  /** The different possible comment actions for the project's topics */
  comment_actions: CommentActions[];
  /** The different existing custom fields for the project's topics */
  custom_fields: ProjectCustomField[];
};

/** Represents the type of relation between topics */
export enum RelationType {
  /** Indicates that the topic is related to another one*/
  relates = "relates",
  /** Indicates that the topic is parent of another one */
  parent = "parent",
  /** Indicates that the topic is child of another one */
  child = "child",
}

/** Represents the response for getting a related topic */
export type GetRelatedTopic = {
  /** The related topic guid */
  related_topic_guid: string;
  /** The type of relation shared with this related topic */
  relation_type: RelationType;
};

/** Represents the authorization information for a topic */
export type TopicAuthorization = {
  /** List of possible permissions/actions that the user have for the current topic */
  topic_actions: TopicActions[];
  /** The status of the topic*/
  topic_status: string[];
};

/** Represents a response object for getting a topic extension item */
export type GetTopicExtensionItem = {
  /** The id of the value to set */
  id: string;
  /** The name of the value */
  name: string;
};

/**
 * Represents a topic retrieved from the BCF Services API
 * For fields description, see the BCF API documentation at:
 * https://github.com/buildingSMART/BCF-API/?tab=readme-ov-file#321-get-topics-service
 */
export type GetTopic = {
  guid: string;
  server_assigned_id: string;
  reference_links: string[];
  title: string;
  labels: GetTopicExtensionItem[];
  creation_date: string;
  creation_author: CollaborationUser;
  custom_fields: TopicCustomField[];
  related_topics: GetRelatedTopic[];
  authorization: TopicAuthorization;

  topic_type?: GetTopicExtensionItem | null;
  topic_status?: GetTopicExtensionItem | null;
  priority?: GetTopicExtensionItem | null;
  modified_date?: string | null;
  modified_author?: CollaborationUser | null;
  assigned_to?: CollaborationUser | null;
  stage?: GetTopicExtensionItem | null;
  description?: string | null;
  due_date?: string | null;
  main_viewpoint_guid?: string | null;
};
