import {
  PropOptional,
  validateArrayOf,
  validateEnumValue,
  validateNonEmptyString,
  validateNotNullishObject,
  validateOfType,
  validatePrimitive,
  validatePropertyEnumValue,
} from "@faro-lotv/foundation";
import {
  PostTopic,
  SetTopicExtensionItem,
} from "./bcf-services-api-client-parameters";
import {
  CollaborationUser,
  CommentActions,
  GetProject,
  GetProjectExtensions,
  GetRelatedTopic,
  GetTopic,
  GetTopicExtensionItem,
  ProjectActions,
  ProjectAuthorization,
  ProjectCustomField,
  ProjectCustomFieldType,
  ProjectEnumValue,
  ProjectExtensionItem,
  ProjectTopicStatusExtensionItem,
  ProjectTopicStatusType,
  ProjectUserExtensionItem,
  RelationType,
  TopicActions,
  TopicAuthorization,
} from "./bcf-services-api-responses";
import { TopicCustomField } from "./bcf-services-api-types";

/**
 * Checks if the given object is a ProjectAuthorization.
 *
 * @param authorization - The object to check.
 * @returns A boolean indicating whether the object is a ProjectAuthorization.
 */
export function isProjectAuthorization(
  authorization: unknown,
): authorization is ProjectAuthorization {
  return (
    validateNotNullishObject(authorization, "ProjectAuthorization") &&
    validateArrayOf({
      object: authorization,
      prop: "project_actions",
      elementGuard: (projectAction) =>
        validateEnumValue(projectAction, ProjectActions),
    })
  );
}

/**
 * Checks if the response is a valid TopicCustomField.
 *
 * @param response The response to validate.
 * @returns True if the response is a valid TopicCustomField, false otherwise.
 */
export function isTopicCustomField(
  response: unknown,
): response is TopicCustomField {
  return (
    validateNotNullishObject(response, "TopicCustomField") &&
    validateNonEmptyString(response, "id") &&
    validateArrayOf({
      object: response,
      prop: "values",
      elementGuard: (value) => typeof value === "string",
    })
  );
}

/**
 * Checks if the given object is a GetProject.
 *
 * @param project - The object to check.
 * @returns A boolean indicating whether the object is a GetProject.
 */
export function isGetProject(project: unknown): project is GetProject {
  return (
    validateNotNullishObject(project, "GetProject") &&
    validateNonEmptyString(project, "project_id") &&
    validateNonEmptyString(project, "name") &&
    validateOfType(project, "authorization", isProjectAuthorization)
  );
}

/**
 * Checks if the given response is an array of GetProject objects.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is an array of GetProject objects.
 */
export function isGetAllProjects(response: unknown): response is GetProject[] {
  return Array.isArray(response) && response.every(isGetProject);
}

/**
 * Checks if the given response is a ProjectExtensionItem.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a ProjectExtensionItem.
 */
export function isProjectExtensionItem(
  response: unknown,
): response is ProjectExtensionItem {
  return (
    validateNotNullishObject(response, "ProjectExtensionItem") &&
    validateNonEmptyString(response, "id") &&
    validateNonEmptyString(response, "name")
  );
}

/**
 * Checks if the given response is a CollaborationUser.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a CollaborationUser.
 */
export function isCollaborationUser(
  response: unknown,
): response is CollaborationUser {
  return (
    validateNotNullishObject(response, "CollaborationUser") &&
    validateNonEmptyString(response, "id") &&
    validatePrimitive(response, "name", "string", PropOptional) &&
    validatePrimitive(response, "email", "string", PropOptional)
  );
}

/**
 * Checks if the given response is a ProjectUserExtensionItem.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a ProjectUserExtensionItem.
 */
export function isProjectUserExtensionItem(
  response: unknown,
): response is ProjectUserExtensionItem {
  return (
    validateNotNullishObject(response, "ProjectUserExtensionItem") &&
    validateNonEmptyString(response, "id") &&
    validateNonEmptyString(response, "name") &&
    validatePrimitive(response, "email", "string", PropOptional) &&
    validatePrimitive(response, "readonly", "boolean")
  );
}

/**
 * Checks if the given response is a ProjectTopicStatusExtensionItem.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a ProjectTopicStatusExtensionItem.
 */
export function isProjectTopicStatusExtensionItem(
  response: unknown,
): response is ProjectTopicStatusExtensionItem {
  return (
    validateNotNullishObject(response, "ProjectTopicStatusExtensionItem") &&
    validateNonEmptyString(response, "id") &&
    validateNonEmptyString(response, "name") &&
    validatePropertyEnumValue(response, "status_type", ProjectTopicStatusType)
  );
}

/**
 * Checks if the given response is a valid ProjectEnumValue.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a valid ProjectEnumValue object.
 */
export function isProjectEnumValue(
  response: unknown,
): response is ProjectEnumValue {
  return (
    validateNotNullishObject(response, "ProjectEnumValue") &&
    validateNonEmptyString(response, "value") &&
    validateNonEmptyString(response, "displayValue") &&
    validatePrimitive(response, "readonly", "boolean")
  );
}

/**
 * Checks if the given response is a valid ProjectCustomField object.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a valid ProjectCustomField object.
 */
export function isProjectCustomField(
  response: unknown,
): response is ProjectCustomField {
  return (
    validateNotNullishObject(response, "ProjectCustomField") &&
    validateNonEmptyString(response, "id") &&
    validateNonEmptyString(response, "name") &&
    validatePropertyEnumValue(response, "type", ProjectCustomFieldType) &&
    validatePrimitive(response, "readonly", "boolean") &&
    validatePrimitive(response, "minArraySize", "number") &&
    validatePrimitive(response, "maxArraySize", "number", PropOptional) &&
    validatePrimitive(response, "defaultValue", "string", PropOptional) &&
    validateArrayOf({
      object: response,
      prop: "enumValues",
      elementGuard: isProjectEnumValue,
      optionality: PropOptional,
    })
  );
}

/**
 * Checks if the given response object is of type GetProjectExtensions.
 *
 * @param response - The response object to be validated.
 * @returns A boolean indicating whether the response object is of type GetProjectExtensions.
 */
export function isGetProjectExtensions(
  response: unknown,
): response is GetProjectExtensions {
  return (
    validateNotNullishObject(response, "GetProjectExtensions") &&
    validateArrayOf({
      object: response,
      prop: "topic_type",
      elementGuard: isProjectExtensionItem,
    }) &&
    validateArrayOf({
      object: response,
      prop: "topic_status",
      elementGuard: isProjectTopicStatusExtensionItem,
    }) &&
    validateArrayOf({
      object: response,
      prop: "topic_label",
      elementGuard: isProjectExtensionItem,
    }) &&
    validateArrayOf({
      object: response,
      prop: "priority",
      elementGuard: isProjectExtensionItem,
    }) &&
    validateArrayOf({
      object: response,
      prop: "users",
      elementGuard: isProjectUserExtensionItem,
    }) &&
    validateArrayOf({
      object: response,
      prop: "stage",
      elementGuard: isProjectExtensionItem,
    }) &&
    validateArrayOf({
      object: response,
      prop: "project_actions",
      elementGuard: (projectAction) =>
        validateEnumValue(projectAction, ProjectActions),
    }) &&
    validateArrayOf({
      object: response,
      prop: "topic_actions",
      elementGuard: (topicAction) =>
        validateEnumValue(topicAction, TopicActions),
    }) &&
    validateArrayOf({
      object: response,
      prop: "comment_actions",
      elementGuard: (commentAction) =>
        validateEnumValue(commentAction, CommentActions),
    }) &&
    validateArrayOf({
      object: response,
      prop: "custom_fields",
      elementGuard: isProjectCustomField,
    })
  );
}

/**
 * Checks if the given response is a SetTopicExtensionItem.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a SetTopicExtensionItem.
 */
export function isSetTopicExtensionItem(
  response: unknown,
): response is SetTopicExtensionItem {
  return (
    validateNotNullishObject(response, "SetTopicExtensionItem") &&
    validateNonEmptyString(response, "id")
  );
}

/**
 * Checks if the given response is a GetTopicExtensionItem.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a GetTopicExtensionItem.
 */
export function isGetTopicExtensionItem(
  response: unknown,
): response is GetTopicExtensionItem {
  return (
    validateNotNullishObject(response, "GetTopicExtensionItem") &&
    validateNonEmptyString(response, "id") &&
    validateNonEmptyString(response, "name")
  );
}

/**
 * Checks if the given value is a valid date string.
 *
 * @param dateString - The value to check.
 * @param isOptional - A boolean indicating whether the value is optional.
 * @returns A boolean indicating whether the value is a valid date string.
 */
export function isValidDateString(
  dateString: unknown,
  isOptional: boolean,
): boolean {
  if (dateString === undefined || dateString === null) {
    return isOptional;
  }
  if (typeof dateString !== "string") {
    return false;
  }
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

/**
 * Checks if the given response is a GetRelatedTopic.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a GetRelatedTopic.
 */
export function isGetRelatedTopic(
  response: unknown,
): response is GetRelatedTopic {
  return (
    validateNotNullishObject(response, "GetRelatedTopic") &&
    validateNonEmptyString(response, "related_topic_guid") &&
    validatePropertyEnumValue(response, "relation_type", RelationType)
  );
}

/**
 * Checks if the given response is a TopicAuthorization.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a TopicAuthorization.
 */
export function isTopicAuthorization(
  response: unknown,
): response is TopicAuthorization {
  return (
    validateNotNullishObject(response, "TopicAuthorization") &&
    validateArrayOf({
      object: response,
      prop: "topic_actions",
      elementGuard: (topicAction) =>
        validateEnumValue(topicAction, TopicActions),
    }) &&
    validateArrayOf({
      object: response,
      prop: "topic_status",
      elementGuard: (topicStatus) => typeof topicStatus === "string",
    })
  );
}

/**
 * Checks if the given response is a GetTopic object.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a GetTopic object.
 */
export function isGetTopic(response: unknown): response is GetTopic {
  return (
    validateNotNullishObject(response, "GetTopic") &&
    validateNonEmptyString(response, "guid") &&
    validateNonEmptyString(response, "server_assigned_id") &&
    validateArrayOf({
      object: response,
      prop: "reference_links",
      elementGuard: (link) => typeof link === "string",
    }) &&
    validateNonEmptyString(response, "title") &&
    validateArrayOf({
      object: response,
      prop: "labels",
      elementGuard: isGetTopicExtensionItem,
    }) &&
    validateOfType(response, "creation_date", (dateString) =>
      isValidDateString(dateString, false),
    ) &&
    validateOfType(response, "creation_author", isCollaborationUser) &&
    validateArrayOf({
      object: response,
      prop: "custom_fields",
      elementGuard: isTopicCustomField,
    }) &&
    validateArrayOf({
      object: response,
      prop: "related_topics",
      elementGuard: isGetRelatedTopic,
    }) &&
    validateOfType(response, "authorization", isTopicAuthorization) &&
    validateOfType(
      response,
      "topic_type",
      isGetTopicExtensionItem,
      PropOptional,
    ) &&
    validateOfType(
      response,
      "topic_status",
      isGetTopicExtensionItem,
      PropOptional,
    ) &&
    validateOfType(
      response,
      "priority",
      isGetTopicExtensionItem,
      PropOptional,
    ) &&
    validateOfType(
      response,
      "modified_date",
      (dateString) => isValidDateString(dateString, false),
      PropOptional,
    ) &&
    validateOfType(
      response,
      "modified_author",
      isCollaborationUser,
      PropOptional,
    ) &&
    validateOfType(
      response,
      "assigned_to",
      isCollaborationUser,
      PropOptional,
    ) &&
    validateOfType(response, "stage", isGetTopicExtensionItem, PropOptional) &&
    validatePrimitive(response, "description", "string", PropOptional) &&
    validateOfType(
      response,
      "due_date",
      (dateString) => isValidDateString(dateString, false),
      PropOptional,
    ) &&
    validatePrimitive(response, "main_viewpoint_guid", "string", PropOptional)
  );
}

/**
 * Checks if the given response is an array of GetTopic objects.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is an array of GetTopic objects.
 */
export function isGetAllTopics(response: unknown): response is GetTopic[] {
  return Array.isArray(response) && response.every(isGetTopic);
}

/**
 * Checks if the given response is a PostTopic.
 *
 * @param response - The response to check.
 * @returns A boolean indicating whether the response is a PostTopic.
 */
export function isPostTopic(response: unknown): response is PostTopic {
  return (
    validateNotNullishObject(response, "PostTopic") &&
    validateNonEmptyString(response, "title") &&
    validatePrimitive(response, "guid", "string", PropOptional) &&
    validateOfType(
      response,
      "topic_type",
      isSetTopicExtensionItem,
      PropOptional,
    ) &&
    validateOfType(
      response,
      "topic_status",
      isSetTopicExtensionItem,
      PropOptional,
    ) &&
    validateArrayOf({
      object: response,
      prop: "reference_links",
      elementGuard: (link) => typeof link === "string",
      optionality: PropOptional,
    }) &&
    validateOfType(
      response,
      "priority",
      isSetTopicExtensionItem,
      PropOptional,
    ) &&
    validateArrayOf({
      object: response,
      prop: "labels",
      elementGuard: isSetTopicExtensionItem,
      optionality: PropOptional,
    }) &&
    validateOfType(
      response,
      "assigned_to",
      isSetTopicExtensionItem,
      PropOptional,
    ) &&
    validateOfType(response, "stage", isSetTopicExtensionItem, PropOptional) &&
    validatePrimitive(response, "description", "string", PropOptional) &&
    validateOfType(
      response,
      "due_date",
      (dateString) => isValidDateString(dateString, true),
      PropOptional,
    ) &&
    validateArrayOf({
      object: response,
      prop: "custom_fields",
      elementGuard: isTopicCustomField,
      optionality: PropOptional,
    }) &&
    validatePrimitive(response, "main_viewpoint_guid", "string", PropOptional)
  );
}
