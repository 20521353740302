import {
  Drawer,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { UserDisplayInfo } from "../../types/user-display-info";
import {
  FaroIconButton,
  FaroIconButtonSizes,
} from "../icon-button/faro-icon-button";
import { IconButton } from "../icon-button/icon-button";
import {
  HamburgerMenuIcon,
  QuestionMarkInCircleIcon,
  SphereXGTextIcon,
} from "../icons/icons";
import { NoTranslate } from "../no-translate";
import { UserAvatar } from "../user-avatar/user-avatar";
import {
  MenuAccountSecurityOption,
  MenuSignOutOption,
  UserMenu,
} from "../user-menu/user-menu";
import { Link } from "../user-menu/user-menu-types";
import { AppBar } from "./app-bar";

export type HeaderBarProps = {
  /** Content to display after the logo, at the start of the HeaderBar. */
  content?: ReactNode;

  /**
   * Object that contains the links needed for the header.
   * Example (links={runtimeConfig.externalLinks})
   */
  links?: {
    /** Url to the dashboard (Eg: "https://dashboard.holobuilder.com") */
    dashboardUrl?: string;
    /** Url link to the faro terms of service page (Eg. https://www.farosphere.com/home/legal/terms-of-service) */
    termsOfServiceUrl?: string;
    /** Url link to the faro privacy policy page (Eg. https://www.farosphere.com/home/legal/privacy-policy) */
    privacyPolicyUrl: string;
    /** Url link to the faro imprint page (Eg. https://www.faro.com/de-DE/Imprint) */
    imprintUrl: string;
  };

  /** Property that describes if the QuickHelp side-panel is open or not. */
  isQuickHelpOpen?: boolean;

  /** Function to call when the QuickHelp button is clicked. */
  onQuickHelpClick?(): void;

  /** Should the profile button be visible on the header bar? */
  showProfileButton?: boolean;

  /** Info about the user */
  userDisplayInfo?: UserDisplayInfo;

  /** Current version of the app. It will be displayed in the about dialog in the user menu */
  appVersion?: string;

  /** Callback for then the log in button is clicked. */
  onLogInClick(): void;

  /** Callback for when the log out button is clicked. */
  onLogOutClick(): void;

  /** Callback for when the Account & Security option is clicked. */
  onAccountAndSecurityClick(): void;

  /**
   * Element to display in the collapsible panel of hamburger menu,
   * if not provided, the hamburger menu will not be displayed.
   *
   * @example
   * // To handle the drawer visible/hidden state in a responsive manner, you can see the following example:
   * const isMdUp = useBreakpointSmUp();
   * <HeaderBar
        drawerContent={isMdUp ? undefined : <ProjectOverviewBase />}
        // Other props
      >
     {children}
    </HeaderBar>
   */
  drawerContent?: JSX.Element;
};

/**
 * @returns A header bar that contains some utility buttons.
 */
export function HeaderBar({
  children,
  content,
  links,
  isQuickHelpOpen,
  onQuickHelpClick,
  showProfileButton = true,
  userDisplayInfo,
  appVersion,
  onLogInClick,
  onLogOutClick,
  onAccountAndSecurityClick,
  drawerContent,
}: PropsWithChildren<HeaderBarProps>): JSX.Element {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const profileButtonRef = useRef<HTMLButtonElement>(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const menuLinks = useMemo<Link[]>(
    () => [
      { href: links?.termsOfServiceUrl, text: "TOS" },
      { href: links?.privacyPolicyUrl, text: "Privacy" },
      { href: links?.imprintUrl, text: "Imprint" },
    ],
    [links?.imprintUrl, links?.privacyPolicyUrl, links?.termsOfServiceUrl],
  );

  const handleDrawerToggle = useCallback(() => {
    setIsDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen]);

  return (
    <>
      <AppBar
        logoUrl={links?.dashboardUrl}
        sx={{ zIndex: 1 }}
        shouldShowLogo={!drawerContent}
      >
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          {drawerContent && (
            <FaroIconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <HamburgerMenuIcon />
            </FaroIconButton>
          )}

          <Typography
            component="div"
            variant="body2"
            sx={{ p: 0, m: 0, ml: 2, color: "black" }}
          >
            {content}
          </Typography>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            {children}

            {isQuickHelpOpen !== undefined && (
              <FaroIconButton
                size={FaroIconButtonSizes.m}
                onClick={onQuickHelpClick}
                color={isQuickHelpOpen ? "primary.main" : "secondary.main"}
              >
                <QuestionMarkInCircleIcon />
              </FaroIconButton>
            )}

            {showProfileButton && (
              <Tooltip
                title={
                  userDisplayInfo?.email ? (
                    <NoTranslate>{userDisplayInfo.email}</NoTranslate>
                  ) : (
                    "Login"
                  )
                }
              >
                <IconButton
                  onClick={() =>
                    userDisplayInfo?.email
                      ? setIsProfileMenuOpen(true)
                      : onLogInClick()
                  }
                  ref={profileButtonRef}
                >
                  <UserAvatar
                    userDisplayInfo={userDisplayInfo}
                    shouldShowGradientBorder
                    size="s"
                  />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          {profileButtonRef.current && userDisplayInfo && (
            <UserMenu
              anchorElement={profileButtonRef.current}
              open={isProfileMenuOpen}
              closeMenu={() => setIsProfileMenuOpen(false)}
              userDisplayInfo={userDisplayInfo}
              links={menuLinks}
              appVersion={appVersion}
            >
              <MenuAccountSecurityOption onClick={onAccountAndSecurityClick} />
              <MenuSignOutOption onClick={onLogOutClick} />
            </UserMenu>
          )}
        </Stack>
      </AppBar>
      {drawerContent && (
        <Drawer
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          variant="temporary"
          ModalProps={{
            // Better open performance on mobile as the content are always available in the DOM tree
            // and saves the overhead of unmounting and mounting of the content
            keepMounted: true,
          }}
          PaperProps={{
            sx: { pl: 2.5, pr: 1, pt: 2, width: "85%" },
          }}
        >
          <MuiLink sx={{ display: "contents" }} href={links?.dashboardUrl}>
            <SphereXGTextIcon sx={{ width: "6.5rem", height: "3rem" }} />
          </MuiLink>
          {drawerContent}
        </Drawer>
      )}
    </>
  );
}
