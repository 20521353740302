import { CSSProperties } from "react";
import { blue, green, neutral, purple, yellow } from "../../colors";
import { FontWeights } from "../../faro-theme";
import { FaroChip } from "../chip";

export enum StatusOptions {
  open = "open",
  inProgress = "in_progress",
  pending = "pending",
  toReview = "to_review",
  resolved = "resolved",
  unclassified = "unclassified",
}

/** Project markup status to display in readable text */
const faroStatusText: {
  [key in StatusOptions]: string;
} = {
  [StatusOptions.open]: "To-do",
  [StatusOptions.inProgress]: "In progress",
  [StatusOptions.pending]: "Pending",
  [StatusOptions.toReview]: "In review",
  [StatusOptions.resolved]: "Resolved",
  [StatusOptions.unclassified]: "Unclassified",
};

interface Props {
  /** The status option for the tag */
  status: StatusOptions;

  /**
   * True if the annotation tag has to be in dark mode
   *
   * @default false
   */
  dark?: boolean;

  /**
   * The size of the tag
   *
   * @default "small"
   */
  size?: "small" | "large";
}

/**
 * Renders a status-tag/-chip. Currently used for the status of a project annotation.
 *
 * @returns JSX.Element - The status of the project annotation as a tag/chip
 */
export function FaroChipStatus({
  status,
  dark = false,
  size = "small",
}: Props): JSX.Element {
  let color: CSSProperties["color"] = neutral[800];
  let backgroundColor: CSSProperties["color"] = neutral[0];
  let borderColor: CSSProperties["color"] = neutral[300];
  let label = "Unclassified";

  const darkThemeBgColor = `${neutral[0]}1A`;

  switch (status) {
    case StatusOptions.open:
      color = dark ? neutral[200] : neutral[700];
      backgroundColor = neutral[100];
      borderColor = neutral[300];
      label = faroStatusText.open;
      break;

    case StatusOptions.inProgress:
      color = dark ? blue[300] : blue[500];
      backgroundColor = blue[50];
      borderColor = blue[100];
      label = faroStatusText.in_progress;
      break;

    case StatusOptions.pending:
      color = yellow[500];
      backgroundColor = yellow[50];
      borderColor = yellow[100];
      label = faroStatusText.pending;
      break;

    case StatusOptions.toReview:
      color = dark ? purple[300] : purple[500];
      backgroundColor = purple[50];
      borderColor = purple[100];
      label = faroStatusText.to_review;
      break;

    case StatusOptions.resolved:
      color = dark ? green[600] : green[700];
      backgroundColor = green[50];
      borderColor = green[100];
      label = faroStatusText.resolved;
      break;

    case StatusOptions.unclassified:
      color = dark ? neutral[400] : neutral[500];
      backgroundColor = neutral[0];
      borderColor = neutral[300];
      label = faroStatusText.unclassified;
      break;
  }

  return (
    <FaroChip
      label={label}
      color={color}
      backgroundColor={dark ? darkThemeBgColor : backgroundColor}
      borderColor={dark ? darkThemeBgColor : borderColor}
      variant="outlined"
      size={size === "small" ? "small" : "medium"}
      textTransform={size === "small" ? "uppercase" : "none"}
      sx={{
        ...(size === "large" && {
          fontWeight: FontWeights.SemiBold,
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
        }),
        "& .MuiChip-labelSmall": {
          px: 1,
          py: 0.5,
        },
        "& .MuiChip-labelMedium": {
          px: 1.5,
          py: 0.625,
        },
        "& .MuiChip-icon": {
          marginLeft: 1,
          marginRight: 0,
          color,
        },
      }}
    />
  );
}
