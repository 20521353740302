import {
  Optional,
  PropOptional,
  validateArrayOf,
  validateEnumValue,
  validateNotNullishObject,
  validateOfType,
  validatePrimitive,
} from "@faro-lotv/foundation";
import {
  GUID,
  IElementBase,
  IElementType,
  IElementTypeHint,
  ILabel,
  IPose,
  IQuat,
  ISOTimeString,
  IVec3,
  validateLabel,
  validateQuat,
  validateVec3,
} from "@faro-lotv/ielement-types";

/**
 * Helper type to mark fields as optional in creation payloads of a single IElement
 *
 * This type only serves to construct the CreateIElement type and should not be used.
 */
type CreateIElementSingle<IElementType extends IElementBase> = Omit<
  Optional<
    IElementType,
    | "createdBy"
    | "modifiedBy"
    | "createdAt"
    | "modifiedAt"
    | "descr"
    | "thumbnailUri"
    | "lastModifiedInDb"
    | "labels"
    | "pose"
    | "metaDataMap"
    | "typeHint"
  >,
  // Don't use deprecated properties
  "root_Id" | "parent_Id" | "children_Ids" | "metaData"
>;

/**
 * Helper type to mark fields as optional in creation payloads of IElements.
 */
export type CreateIElement<IElementType extends IElementBase> =
  // This special looking construction is needed to preserve the distributivity across a union type.
  // e.g. CreateIElement<Img360 | ImgSheet> = CreateIElement<Img360> | CreateIElement<ImgSheet>
  //
  // Without this additional narrowing step, the TS compiler loses to ability to discriminate on the resulting
  // type. For example if two IElements of the union have different fields, none of them can be assigned, because it does
  // not exist on the "merged" object after the Omit<> operation.
  IElementType extends IElementBase
    ? CreateIElementSingle<IElementType>
    : never;

export interface MutationResult {
  type: "MutationResult";

  /** The ID of the corresponding Mutation request */
  referenceId: string | null;

  /** Status of the mutation */
  status: "success" | "failure" | "pending";

  // TODO: Add docs
  message: string | null;
}

/** Possible status of a ProjectApi project */
export enum ProjectStatus {
  /** The project is valid and in sync with WebEditor */
  active = "active",

  /** The project is valid but some changes from WebEditor changes are still pending */
  outdated = "outdated",

  /** The project is not valid anymore */
  blacklisted = "blacklisted",
}

/**
 * A type guard to check if a string is a valid ProjectStatus enum value
 *
 * @param data to check
 * @returns true if data is one of the valid ProjectStatus values
 */
export function isProjectStatus(data: unknown): data is ProjectStatus {
  if (typeof data !== "string") {
    return false;
  }
  return Object.values<string>(ProjectStatus).includes(data);
}

export enum TaskResultStatus {
  Success = "Success",
  Failure = "Failure",
  Running = "Running",
}

/** The state of a current task mutating a project */
export type TaskResult = {
  /** Id of the task */
  id: GUID;

  /** Id of the project mutated by this task */
  projectId: GUID;

  /** Task type */
  type: string;

  /** Status of this task */
  status: TaskResultStatus;
};

/**
 * @returns true if the data is a valid TaskResult type
 * @param data to validate
 */
export function isTaskResult(data: unknown): data is TaskResult {
  if (!validateNotNullishObject(data, "TaskResult")) {
    return false;
  }

  const taskResult: Partial<TaskResult> = data;

  return (
    typeof taskResult.id === "string" &&
    typeof taskResult.projectId === "string" &&
    typeof taskResult.type === "string" &&
    typeof taskResult.status === "string" &&
    Object.values<string>(TaskResultStatus).includes(taskResult.status)
  );
}

type ApiResponseMutationError = {
  /** Name of the mutation that failed or the name of the iElement on which the mutation failed */
  ObjType: string;

  /** Name of the mutation that failed or the non valid content passed to the mutation */
  ObjectInfo: string;

  /** List of issues (property name and error message) encountered with the mutation */
  Problems: ApiResponseErrorProblems[];
};

type ApiResponseErrorProblems = {
  /** Error message returned by the backend */
  ErrorMessage: string;

  /** Name of the property of the IElement that failed the mutation */
  MemberNames: string[];
};

/**
 * @param error object to check
 * @returns true if the passed object is an ApiResponseMutationError
 */
export function isApiResponseMutationErrorMessage(
  error?: unknown,
): error is ApiResponseMutationError {
  return (
    !!error &&
    validatePrimitive(error, "ObjType", "string") &&
    validatePrimitive(error, "ObjectInfo", "string") &&
    validateArrayOf({
      object: error,
      prop: "Problems",
      elementGuard: isApiResponseErrorProblems,
    })
  );
}

function isApiResponseErrorProblems(
  error: unknown,
): error is ApiResponseErrorProblems {
  return (
    validatePrimitive(error, "ErrorMessage", "string") &&
    validateArrayOf({
      object: error,
      prop: "MemberNames",
      elementGuard: (el) => typeof el === "string",
    })
  );
}

/** A structure from the local pose of a DataSet computed inside an Area */
export type DataSetLocalPose = Required<Pick<IPose, "pos" | "rot" | "scale">>;

/** The information of a DataSet inside an Area obtained trough a Volume Query */
export type DataSetAreaInfo = {
  /** Id of the DataSet */
  elementId: GUID;

  /** Type of the DataSet */
  elementType: IElementType;

  /** TypeHint of the DataSet */
  elementTypeHint: IElementTypeHint | string;

  /** The local pose of the DataSet inside the area Volume */
  pose: DataSetLocalPose;
};

/**
 * @param data to check
 * @returns true if it matches the DataSetLocalPose type
 */
export function isDataSetLocalPose(data: unknown): data is DataSetLocalPose {
  if (!validateNotNullishObject(data, "DataSetLocalPose")) {
    return false;
  }

  const pose: Partial<DataSetLocalPose> = data;

  return (
    validateOfType(pose, "pos", validateVec3) &&
    validateOfType(pose, "scale", validateVec3) &&
    validateOfType(pose, "rot", validateQuat)
  );
}

function isDataSetAreaInfo(data: unknown): data is DataSetAreaInfo {
  if (!validateNotNullishObject(data, "DataSetAreaInfo")) {
    return false;
  }

  const info: Partial<DataSetAreaInfo> = data;

  return (
    validatePrimitive(info, "elementId", "string") &&
    validateEnumValue(info.elementType, IElementType) &&
    validatePrimitive(info, "elementTypeHint", "string") &&
    isDataSetLocalPose(info.pose)
  );
}

export type PaginatedAreaVolumeResponse = {
  /** One page of info about DataSets inside an Area volume */
  page: DataSetAreaInfo[];

  /** Token for the next page of DataSets in multi-page responses */
  token: string | null;
};

/**
 * @param data the object returned by the API backend
 * @returns a page of DataSets contained in an Area
 */
export function isPaginatedAreaVolumeResponse(
  data: unknown,
): data is PaginatedAreaVolumeResponse {
  if (!validateNotNullishObject(data, "PaginatedAreaVolumeResponse")) {
    return false;
  }

  const response: Partial<PaginatedAreaVolumeResponse> = data;

  return (
    validateArrayOf({
      object: response,
      prop: "page",
      elementGuard: isDataSetAreaInfo,
    }) && validatePrimitive(response, "token", "string", PropOptional)
  );
}

export type SignElementRequest = {
  /** The parent IElement ID that the URLs are children of */
  elementId: GUID;

  /** A collection of URLs that should be signed. */
  urls: string[];
};

export type SignUrlsParams = {
  /** abort signal for the request */
  signal?: AbortSignal;

  /** A collection of elements and associated URLs that should be signed */
  elements: SignElementRequest[];
};

export enum SignElementUrlResult {
  succeeded = "Succeeded",
  invalidProjectIdSegment = "InvalidProjectIdSegment",
  notSignable = "NotSignable",
  ignored = "Ignored",
}

export interface SignUrlResult {
  result: SignElementUrlResult;

  /** The original URL */
  url: string;

  /** The signed version of the original URL (if successful) */
  signedUrl: string | null;
}

export enum SignElementUrlsOutcome {
  succeeded = "Succeeded",
  partiallySucceeded = "PartiallySucceeded",
  invalidElementId = "InvalidElementId",
  inconclusive = "Inconclusive",
}

export interface SignElementResult {
  outcome: SignElementUrlsOutcome;

  /** The requested element ID */
  elementId: GUID;

  /** A collection of (maybe) signed URLs for this element ID */
  urls: SignUrlResult[];
}

export interface SignUrlsResponse {
  /** The time at which the signed URLs becomes invalid */
  expiresOn: ISOTimeString;

  /** A collection of (maybe) signed elements and URLs */
  elements: SignElementResult[];
}

/**
 * @param data to check
 * @returns true if it matches the SignUrlsResponse interface
 */
export function isSignUrlsResponse(data: unknown): data is SignUrlsResponse {
  return (
    validateNotNullishObject(data, "SignUrlsResponse") &&
    validatePrimitive(data, "expiresOn", "string") &&
    validateArrayOf({
      object: data,
      prop: "elements",
      elementGuard: (el) =>
        validateNotNullishObject(el, "SignUrlsResponse.elements") &&
        validateEnumValue(el.outcome, SignElementUrlsOutcome) &&
        validateArrayOf({
          object: el,
          prop: "urls",
          elementGuard: (url) =>
            validateNotNullishObject(url, "SignUrlsResponse.elements.url") &&
            validateEnumValue(url.result, SignElementUrlResult) &&
            validatePrimitive(url, "url", "string") &&
            validatePrimitive(url, "signedUrl", "string", PropOptional),
        }),
    })
  );
}

/** All the project labels */
export type ProjectLabels = {
  /** All the labels */
  allUncategorized: ILabel[];

  /** User defined labels, used by the users to categorize elements */
  allowedToUse: ILabel[];

  /** Labels defined by the Sphere XG system and backend to categorize elements (Eg. VideoMode) */
  system: ILabel[];
};

/**
 * @param payload to check
 * @returns true if it matches the ProjectLabels interface
 */
export function isProjectLabels(payload: unknown): payload is ProjectLabels {
  return (
    validateNotNullishObject(payload, "ProjectLabels") &&
    validateArrayOf({
      object: payload,
      prop: "allUncategorized",
      elementGuard: validateLabel,
    }) &&
    validateArrayOf({
      object: payload,
      prop: "allowedToUse",
      elementGuard: validateLabel,
    }) &&
    validateArrayOf({
      object: payload,
      prop: "system",
      elementGuard: validateLabel,
    })
  );
}

type PointCloudAlignmentPose = {
  pos: IVec3;
  scale: IVec3;
  rot: IQuat;
  isWorldPose?: boolean;
};

export interface CloudToBimAlignment {
  pointCloudId: GUID;
  bimModelId: GUID;
  pointCloudPose: PointCloudAlignmentPose;
  cloudToBimElevation: number;
}

type ModifiedElement = {
  id: GUID;
};

export interface CloudToBimAlignmentResponse {
  modifiedIElements: ModifiedElement[];
}
